import { NavigationLink } from './../models/Header';

const homeLinks: NavigationLink[] = [
  {
    id: 0,
    title: 'about',
    linkTo: '/about',
  },
  {
    id: 1,
    title: 'expertise',
    linkTo: '/#expertise',
  },
  {
    id: 2,
    title: 'projects',
    linkTo: '/projects',
  },
  // {
  //   id: 3,
  //   title: 'journal',
  //   linkTo: '/journal',
  // },
  {
    id: 3,
    title: 'contact',
    linkTo: '/#contact',
  },
];

export default homeLinks;
