import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  LinkBox,
  LinkOverlay,
  ResponsiveObject,
  Spinner,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PROJECTS_PAGE_SIZE } from '../constants/Values';
import { Project } from '../models/Project';
import { TextType } from '../models/Text';

import Spacer from './Spacer';
import SquareCard from './SquareCard';
import Text from './Text';

interface ProjectsGalleryWithPaginationProps {
  items: Project[];
  gap?: ResponsiveObject<string>;
  buttonLabel?: string;
}

const ProjectsGalleryWithPagination = ({
  items,
  gap = { base: '8px', lg: '25px' },
  buttonLabel,
}: ProjectsGalleryWithPaginationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 767px)');

  const [isLastOne, setIsLastOne] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(
    Math.min(items.length, PROJECTS_PAGE_SIZE)
  );

  const itemsToDisplay = useMemo<Project[]>(
    () => (isMobile ? items.slice(0, currentIndex) : items),
    [items, isMobile, currentIndex]
  );

  const buttonDisabled = useMemo<boolean>(
    () => (isMobile ? isLastOne : false),
    [isLastOne, isMobile]
  );

  const handleLoadMore = useCallback(() => {
    if (!isLastOne) {
      const diff = items.length - itemsToDisplay.length;
      const newIndex = currentIndex + Math.min(diff, PROJECTS_PAGE_SIZE);
      setCurrentIndex(newIndex);

      if (newIndex >= items.length) {
        setIsLastOne(true);
      }
    }
  }, [isLastOne, items.length, itemsToDisplay.length]);

  const handleGoBack = useCallback(() => {
    navigate('/');
  }, []);

  useEffect(() => {
    if (items.length <= PROJECTS_PAGE_SIZE) {
      setIsLastOne(true);
    } else {
      setIsLastOne(false);
    }

    setCurrentIndex(Math.min(PROJECTS_PAGE_SIZE, items.length));
  }, [items.length]);

  return (
    <>
      <Grid
        flex={1}
        width={'100%'}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        gap={gap}
      >
        {itemsToDisplay.map((project, index) => (
          <GridItem
            key={`item-${project.title}-${index}`}
            w="100%"
            aspectRatio={1}
          >
            <SquareCard
              heroImage={project.heroImage}
              withShadow
              centered
              visibleNode={
                <Center
                  height={'100%'}
                  alignItems={'flex-end'}
                  paddingBottom={'30px'}
                >
                  <Text
                    type={{
                      base: TextType.Inter12Regular,
                      md: TextType.Inter16Regular,
                      lg: TextType.Inter20Regular,
                    }}
                    color="white"
                  >
                    {project.title.toUpperCase()}
                  </Text>
                  <Spacer mobileSize={15} size={30} />
                </Center>
              }
            >
              <Center>
                <Flex
                  height={'100%'}
                  width={'85%'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  marginY={'12px'}
                >
                  <LinkBox>
                    <LinkOverlay href={`/projects/${project.id}`}>
                      <Text
                        type={{
                          base: TextType.Inter14Regular,
                          md: TextType.Inter18Regular,
                          lg: TextType.Inter20Regular,
                        }}
                        color="white"
                      >
                        {project.title.toUpperCase()}
                      </Text>
                    </LinkOverlay>
                  </LinkBox>
                  <Spacer size={12} />
                  <Text
                    type={{
                      base: TextType.Inter12Light,
                      md: TextType.Inter14Light,
                    }}
                    color="white"
                  >
                    {project.shortDescription}
                  </Text>
                  <Spacer size={30} />
                </Flex>
              </Center>
            </SquareCard>
          </GridItem>
        ))}
      </Grid>
      <Button
        variant="solid"
        bgColor={'primary'}
        marginTop={'30px'}
        width={{ base: '50%', md: '33.3333%' }}
        borderRadius={0}
        isDisabled={buttonDisabled}
        onClick={isMobile ? handleLoadMore : handleGoBack}
        spinner={
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="secondary"
          />
        }
      >
        <Text
          type={{ base: TextType.Inter12Bold, md: TextType.Inter14Bold }}
          color="white"
        >
          {(buttonLabel || t('homepage.projects.buttonLabel')).toUpperCase()}
        </Text>
      </Button>
    </>
  );
};

export default ProjectsGalleryWithPagination;
