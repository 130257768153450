import React from 'react';

import { RouterProvider } from 'react-router-dom';

import HeaderProvider from './contexts/useHeader';
import NavigationProvider from './contexts/useNavigation';
import router from './navigation/router';
import './config/i18n';

const App = () => {
  return (
    <NavigationProvider>
      <HeaderProvider>
        <RouterProvider router={router} />
      </HeaderProvider>
    </NavigationProvider>
  );
};

export default App;
