import React, { useCallback } from 'react';

import { render } from '@react-email/render';
import axios from 'axios';

import EmailTemplate from '../components/EmailTemplate';
import { EmailData } from '../models/Form';

const useEmailService = () => {
  const sendEmail = useCallback((data: EmailData) => {
    return new Promise<void>((resolve, reject) => {
      const { firstName, lastName, businessEmail, businessName } = data;
      const emailContent = render(<EmailTemplate {...data} />);

      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/sendEmail`, // TODO: change url
        data: {
          firstName,
          lastName,
          businessName,
          businessEmail,
          message: emailContent,
        },
      })
        .then((response) => {
          if (response.data.status === '200' || response.data.success) {
            resolve();
          } else if (response.data.status === '500') {
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    });
  }, []);

  return {
    sendEmail,
  };
};

export default useEmailService;
