import React from 'react';

import { Box, Grid, GridItem, LinkBox, LinkOverlay } from '@chakra-ui/react';

import {
  COLLAB_1_IMG,
  COLLAB_2_IMG,
  COLLAB_3_IMG,
  COLLAB_4_IMG,
  COLLAB_5_IMG,
  COLLAB_6_IMG,
  FALLBACK_IMAGE,
} from '../../../constants/ImagePaths';

const CollabGrid = () => {
  const getImageUrl = (uri?: string) => {
    if (uri != null && uri !== '') {
      return uri;
    }

    return FALLBACK_IMAGE;
  };

  return (
    <Grid
      templateRows='repeat(1, 1fr)'
      templateColumns={{lg: 'repeat(6, 1fr)', md: 'repeat(6, 1fr)', base: 'repeat(3, 2fr)'}}
      gap={{ base: '6px', md: '25px' }}
      fontWeight="bold"
      width={{ base: '100%', md: '80%' }}
      height={{ base: '100%', md: '100%' }}
      paddingX={{ base: '20px', md: 0 }}
    >
      <GridItem>
        <LinkBox>
          <LinkOverlay href={'https://klmanarchitects.com/'}>
            <Box
              bgImage={getImageUrl(COLLAB_3_IMG)}
              bgPosition={'center'}
              bgRepeat={'no-repeat'}
              bgSize={'contain'}
              width={'100%'}
              height={'200'}
            />
          </LinkOverlay>
        </LinkBox>
      </GridItem>
      <GridItem>
        <LinkBox>
          <LinkOverlay href={'https://davidmorleyarchitects.co.uk/'}>
            <Box
              bgImage={getImageUrl(COLLAB_6_IMG)}
              bgPosition={'center'}
              bgRepeat={'no-repeat'}
              bgSize={'contain'}
              width={'100%'}
              height={'200'}
            />
          </LinkOverlay>
        </LinkBox>
      </GridItem>
      <GridItem>
        <LinkBox>
          <LinkOverlay href={'https://bdl.london/'}> 
            <Box
              bgImage={getImageUrl(COLLAB_2_IMG)}
              bgPosition={'center'}
              bgRepeat={'no-repeat'}
              bgSize={'contain'}
              width={'100%'}
              height={'200'}
            />
          </LinkOverlay>
        </LinkBox>
      </GridItem>
      <GridItem>
        <LinkBox>
          <LinkOverlay href={'https://www.unagru.com/'}>
            <Box
              bgImage={getImageUrl(COLLAB_5_IMG)}
              bgPosition={'center'}
              bgRepeat={'no-repeat'}
              bgSize={'contain'}
              width={'100%'}
              height={'200'}
            />
          </LinkOverlay>
        </LinkBox>
      </GridItem>
      <GridItem>
        <LinkBox>
          <LinkOverlay href={'https://www.helixarchitecture.co.uk/'}>
            <Box
              bgImage={getImageUrl(COLLAB_4_IMG)}
              bgPosition={'center'}
              bgRepeat={'no-repeat'}
              bgSize={'contain'}
              width={'100%'}
              height={'200'}
            />
          </LinkOverlay>
        </LinkBox>
      </GridItem>
      <GridItem>
        <LinkBox>
          <LinkOverlay href={'https://www.soprema.co.uk/en/'}>
            <Box as='button'
              bgImage={getImageUrl(COLLAB_1_IMG)}
              bgPosition={'center'}
              bgRepeat={'no-repeat'}
              bgSize={'contain'}
              width={'100%'}
              height={'200'}
            />
          </LinkOverlay>
        </LinkBox>
      </GridItem>
    </Grid>
  );
};

export default CollabGrid;
