import React from 'react';

import { Box, Button, Spinner } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageWrapper, Text } from '../../components';
import { PageAlignment } from '../../components/PageWrapper';
import { TextType } from '../../models/Text';

const ErrorPage404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <PageWrapper pageAlignment={PageAlignment.CENTER}>
      <Text type={{ base: TextType.Inter12Bold, md: TextType.Inter14Bold }}>
        {t('404.title').toUpperCase()}
      </Text>
      <Text type={{ base: TextType.Inter60Bold, md: TextType.Inter96Bold }}>
        {t('404.errorCode').toUpperCase()}
      </Text>
      <Box width={{ base: '70%', md: '50%', lg: '20%' }}>
        <Text
          type={{ base: TextType.Inter12Bold, md: TextType.Inter14Bold }}
          others={{ textAlign: 'center' }}
        >
          {t('404.subtitle').toUpperCase()}
        </Text>
      </Box>

      <Button
        variant="solid"
        bgColor={'primary'}
        marginTop={'30px'}
        width={{ base: '50%', md: 'auto' }}
        borderRadius={0}
        paddingX={{ base: 0, md: '50px' }}
        spinner={
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="secondary"
          />
        }
        onClick={handleGoBack}
      >
        <Text
          type={{ base: TextType.Inter12Bold, md: TextType.Inter14Bold }}
          color="white"
        >
          {t('404.buttonTitle').toUpperCase()}
        </Text>
      </Button>
    </PageWrapper>
  );
};

export default ErrorPage404;
