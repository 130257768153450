import { Location } from './Location';

export enum ProjectsTypes {
  CONCEPT = 'CONCEPT',
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
}

export interface Project {
  id: number;
  title: string;
  shortDescription: string;
  type: ProjectsTypes;
  heroImage: string;
  category: string;
  client: string;
  location: Location;
  year: string;
  order: number;
  // OPTIONAL PROPS
  collabs?: String[];
  concept?: {
    description: string;
    image?: string;
  };
  process?: {
    description: string;
    image?: string;
  };
  images?: string[];
}
