import React from 'react';

import { ChevronUpIcon } from '@chakra-ui/icons';
import { Center, Container, Flex, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TextType } from '../models/Text';

import Text from './Text';

const Footer = () => {
  const { t } = useTranslation();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <Container
      as="footer"
      bgColor={'gray.700'}
      paddingY={'25px'}
      minWidth={'100%'}
    >
      <Center>
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          width={{ base: '100%', md: '80%' }}
          paddingX={{ base: '20px', md: 0 }}
        >
          <IconButton
            variant={'link'}
            aria-label="Scroll to the top"
            icon={<ChevronUpIcon boxSize={50} />}
            color={'white'}
            size={'md'}
            onClick={handleScrollToTop}
          />
          <Text
            type={{ base: TextType.Inter8Light, md: TextType.Inter10Light }}
            color="white"
            others={{
              marginY: { base: '8px', md: '15px' },
              textAlign: 'justify',
            }}
          >
            {t('footer.developedBy')}
          </Text>
          <Text
            type={{ base: TextType.Inter8Light, md: TextType.Inter10Light }}
            color="white"
            others={{ marginY: { base: '8px', md: '15px' } }}
          >
            {t('footer.description')}
          </Text>
          <Text
            type={{ base: TextType.Inter10Light, md: TextType.Inter12Light }}
            color="white"
            others={{ marginY: { base: '8px', md: '15px' } }}
          >
            {t('footer.copyright')} @{(new Date().getFullYear())}
          </Text>
        </Flex>
      </Center>
    </Container>
  );
};

export default Footer;
