import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  IconButton,
  useMediaQuery,
  SimpleGrid,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { PageWrapper, SectionTitle, Spacer, Text } from '../../../components';
import { FALLBACK_IMAGE } from '../../../constants/ImagePaths';
import { expertiseCards } from '../../../data/mock.data.expertise';
import { Expertise } from '../../../models/Team';
import { TextType } from '../../../models/Text';
import { ExpertiseAdditionalServiceCard, ExpertiseCover } from '../components';

interface ExpertiseSectionProps {
  marginTop?: number;
}

const ExpertiseSection = ({ marginTop = 0 }: ExpertiseSectionProps) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 767px)');

  // big screens size
  const [squareCardSize, setSquareCardSize] = useState<number>(
    (window.innerWidth - 48) / 3
  );

  // mobile size
  const [mobileCoverHeight, setMobileCoverHeight] = useState<number>(0);

  const mainSection = useMemo<Expertise | null>(
    () => expertiseCards.filter((elem) => elem.cover)[0] || null,
    [expertiseCards]
  );

  const secondarySections = useMemo<Expertise[]>(
    () => expertiseCards.filter((elem) => !elem.cover) || [],
    [expertiseCards]
  );

  const handleScrollDown = () => {
    const elem = document.getElementById('additional-services-expertise');
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleResize = useCallback(() => {
    if (!isMobile) {
      const newSize = (window.innerWidth - 48) / 3;
      setSquareCardSize(newSize);
    }
  }, [isMobile]);

  useEffect(() => {
    if (isMobile) {
      const sectionTitle = document.getElementById('expertise-title');
      if (sectionTitle) {
        const coverHeight =
          window.innerHeight -
          sectionTitle.getBoundingClientRect().height -
          marginTop;
        setMobileCoverHeight(coverHeight);
      }
    }
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <PageWrapper id="expertise">
      <Box paddingTop={`${marginTop}px`} />
      <SectionTitle
        id={'expertise-title'}
        title={t('homepage.expertise.title')}
        description={t('homepage.expertise.description')}
      />
      {isMobile ? (
        <>
          {mainSection && (
            <Flex
              height={`${mobileCoverHeight}px`}
              width={'100%'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'stretch'}
            >
              <ExpertiseCover
                title={mainSection.title}
                description={mainSection.description}
                bgUri={mainSection.image || FALLBACK_IMAGE}
              />
              <Flex
                width={'100%'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Text
                  type={TextType.Inter12Light}
                  others={{ marginTop: '10px' }}
                >
                  {t('homepage.projects.additionalServices')}
                </Text>
                <IconButton
                  variant={'link'}
                  aria-label="Scroll down to see the content"
                  icon={<ChevronDownIcon boxSize={50} />}
                  color={'black'}
                  size={'md'}
                  onClick={handleScrollDown}
                />
              </Flex>
            </Flex>
          )}
          <Flex
            id="additional-services-expertise"
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            {secondarySections.map((section, index) => (
              <ExpertiseAdditionalServiceCard
                key={`expertise-section-${index}`}
                ico ={section.lg}
                bgUri={section.image || FALLBACK_IMAGE}
                title={section.title}
                description={section.description}
              />
            ))}
          </Flex>
        </>
      ) : (
        <>
          {mainSection && (
            <Box height={`${squareCardSize}px`} width={'100%'}>
              <ExpertiseCover
                title={mainSection.title}
                description={mainSection.description}
                bgUri={mainSection.image || FALLBACK_IMAGE}
                squareRatio
              />
              <Spacer size={10} />
            </Box>
          )}
          <Spacer size={25} />
          <SimpleGrid
            columns={3}
            spacing={'12px'}
            width={'100%'}
            height={`${squareCardSize}px`}
          >
            {secondarySections.map((section, index) => (
              <ExpertiseAdditionalServiceCard
                key={`expertise-section-${index}`}
                ico ={section.lg}
                bgUri={section.image || FALLBACK_IMAGE}
                title={section.title}
                description={section.description}
              />
            ))}
          </SimpleGrid>
        </>
      )}
    </PageWrapper>
  );
};

export default ExpertiseSection;
