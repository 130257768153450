import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

import { HeaderDimensions } from '../models/Header';

interface HeaderData {
  headerDimensions: HeaderDimensions | null;
  setHeaderDimensions: Dispatch<SetStateAction<HeaderDimensions | null>>;
}

export const HeaderContext = createContext<HeaderData>({} as HeaderData);

const HeaderProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [headerDimensions, setHeaderDimensions] =
    useState<HeaderDimensions | null>(null);

  return (
    <HeaderContext.Provider
      value={{
        headerDimensions,
        setHeaderDimensions,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => useContext(HeaderContext);

export default HeaderProvider;
