import React, { useEffect } from 'react';

import { Footer, Header } from '../../components';
import { useHeader } from '../../contexts/useHeader';
import { useNavigation } from '../../contexts/useNavigation';
import homeLinks from '../../data/mock.data.homepage';

import {
  ExpertiseSection,
  ProjectsSection,
  HowWeWorkSection,
  ContactUsSection,
  Intro,
} from './components';

const HomePage = () => {
  const { headerDimensions } = useHeader();
  const { scrollToAnchor, setScrollToAnchor } = useNavigation();

  useEffect(() => {
    if (scrollToAnchor != null) {
      const element = document.getElementById(scrollToAnchor);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }

      setScrollToAnchor(null);
    }
  }, []);

  return (
    <>
      <Header links={homeLinks} />
      <Intro />
      <ExpertiseSection marginTop={headerDimensions?.height || 0} />
      <HowWeWorkSection marginTop={headerDimensions?.height || 0} />
      <ProjectsSection marginTop={headerDimensions?.height || 0} />
      <ContactUsSection marginTop={headerDimensions?.height || 0} />
      <Footer />
    </>
  );
};

export default HomePage;
