import React, { useState } from 'react';

import { Box, Flex, SlideFade } from '@chakra-ui/react';

interface SquareCardProps {
  heroImage?: string;
  withShadow?: boolean;
  centered?: boolean;
  children: React.ReactNode;
  visibleNode?: React.ReactElement | null;
}

const SquareCard = ({
  heroImage,
  withShadow = false,
  centered = false,
  children,
  visibleNode = null,
}: SquareCardProps) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <Box
      width={'100%'}
      height={'100%'}
      bgImage={heroImage || require('../assets/images/fallback-image.png')}
      backgroundSize={'cover'}
      backgroundPosition={'center'}
      onMouseOver={() => setShowDetails(true)}
      onMouseOut={() => setShowDetails(false)}
    >
      {showDetails ? (
        <Flex
          flex={1}
          height={'100%'}
          flexDirection={'column'}
          justifyContent={'flex-end'}
          alignItems={centered ? 'center' : 'flex-end'}
          bgGradient={
            withShadow
              ? `linear-gradient(180deg, rgba(53, 53, 53, 0.00) 0%, #353535 75%);`
              : undefined
          }
        >
          <SlideFade in={showDetails} offsetY={'30px'}>
            {children}
          </SlideFade>
        </Flex>
      ) : (
        <Flex
          flex={1}
          height={'100%'}
          justifyContent={'center'}
          alignItems={centered ? 'center' : 'flex-end'}
          bgGradient={
            withShadow
              ? `linear-gradient(180deg, rgba(53, 53, 53, 0.00) 65%, #353535 100%);`
              : undefined
          }
        >
          {visibleNode}
        </Flex>
      )}
    </Box>
  );
};

export default SquareCard;
