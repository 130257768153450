// ----------- -> General content <- -----------
export const FALLBACK_IMAGE = require('../assets/images/fallback-image.png');

export const LOGO_WHITE = require('../assets/images/Assets/Logos/logo-classic-white.png');

export const LOGO_BLACK = require('../assets/images/Assets/Logos/logo-classic-black.png');

// ----------- -> #1 Home page content <- -----------
/* Intro Section */
export const INTRO_VIDEO = require('../assets/videos/home-intro.mp4');

export const INTRO_VIDEO_MOBILE = require('../assets/videos/home-intro.mp4');

/* Expertise Section */
export const DESIGN_CARD_IMG = require('../assets/images/Homepage/Expertise_Design.jpg');

export const BIODESIGN_CARD_IMG = require('../assets/images/Homepage/Expertise_Biotekton.jpg');

export const AUTOMATION_CARD_IMG = require('../assets/images/Homepage/Expertise_Automation.jpg');

export const MAPPING_CARD_IMG = require('../assets/images/Homepage/Expertise_Survey.jpg');

export const LOGO_DESIGN = require('../assets/images/logo-design.png');

export const LOGO_MAPPING = require('../assets/images/logo-mapping.png');

export const LOGO_AUTOMATION = require('../assets/images/logo-automation.png');

export const LOGO_BIODESIGN = require('../assets/images/logo-biodesign.png');

/* How We Work Section */
export const HOW_WE_WORK_IMG = require('../assets/images/Homepage/HowWeWork_Desktop.jpg'); // To be added

export const HOW_WE_WORK_IMG_MOBILE = require('../assets/images/Homepage/HowWeWork_Phone.jpg'); // To be added

/* Projects Section */
/*
  See #3
*/

/* Contact Us Section */
export const WORLD_MAP_IMG = require('../assets/images/Homepage/World_Map.jpg');

// ----------- -> #2 About page content <- -----------
/* Meet the Team Section */
/* 
  Define each member image as above -> use require(image_path) <- 
  and on the mock.data.about.ts file import them and add to each project
  in the images array prop.
*/

export const EMP_1 = require('../assets/images/About/Team/Portrait_Lucian.jpg');

export const EMP_2 = require('../assets/images/About/Team/Portrait_Philia.jpg');

export const EMP_3 = require('../assets/images/About/Team/Portrait_Stefan.jpg');

export const EMP_4 = require('../assets/images/About/Team/Portrait_Mihai.jpg');

export const EMP_5 = require('../assets/images/About/Team/Portrait_Madalin.jpg');

export const EMP_6 = require('../assets/images/About/Team/Portrait_Gary.jpg');

/* Collaborators Section */
export const COLLAB_1_IMG = require('../assets/images/About/Collaborators/Logo_Soprema.png');

export const COLLAB_2_IMG = require('../assets/images/About/Collaborators/Logo_BDL.png');

export const COLLAB_3_IMG = require('../assets/images/About/Collaborators/Logo_KLM.png');

export const COLLAB_4_IMG = require('../assets/images/About/Collaborators/Logo_Helix.png');

export const COLLAB_5_IMG = require('../assets/images/About/Collaborators/Logo_Unagru.png');

export const COLLAB_6_IMG = require('../assets/images/About/Collaborators/Logo_DMA.png');

// ----------- -> #3 Projects && Project Details page content <- -----------
/*
  Define each project image as above -> use require(image_path) <- 
  and on the mock.data.projects.ts file import them and add to each project
  in the images array prop
  For each project you'll have: 
    - PROJECT_1_HERO_IMAGE - main picture
    - PROJECT_1_CONCEPT_IMG -  the concept picture
    - PROJECT_1_PROCESS_IMG -  the process picture
    - and PROJECT_1_IMAGE_1, PROJECT_1_IMAGE_2 and so on for each image of the project
*/
export const PROJECT_1_HERO_IMAGE = require('../assets/images/fallback-image.png');

export const PROJECT_2_HERO_IMAGE = require('../assets/images/Projects/Design_Residential/Extension_in_Honor_Oak/EHO_0.jpg');

export const PROJECT_3_HERO_IMAGE = require('../assets/images/Projects/Design_Concept/Concreation/CC_0.jpg');

export const PROJECT_4_HERO_IMAGE = require('../assets/images/Projects/Design_Commercial/Bakery_in_Chelsea/BIC_0.jpg');

export const PROJECT_5_HERO_IMAGE = require('../assets/images/Projects/Design_Residential/Extension_in_Hampstead_Heath/EHH_0.jpg');

export const PROJECT_6_HERO_IMAGE = require('../assets/images/Projects/Design_Concept/Wine_Cultural_Centre/WCC_0.jpg');

export const PROJECT_7_HERO_IMAGE = require('../assets/images/Projects/Design_Commercial/Blackheath_Dental_Practice/BDP_0.jpg');

export const PROJECT_8_HERO_IMAGE = require('../assets/images/Projects/Design_Residential/Extension_in_Beckenham/EB_0.jpg');

export const PROJECT_9_HERO_IMAGE = require('../assets/images/Projects/Design_Concept/Refold_Our_Past_Retold/ROPR_0.jpg');

export const PROJECT_10_HERO_IMAGE = require('../assets/images/Projects/Design_Commercial/Veterinary_Shop_and_Clinic/VSC_0.jpg');

// ----------- -> #5 Privacy Policy page content <- -----------

// ----------- -> Project 1 Modular Homes Additional Images <- -----------

export const PROJECT_1_IMAGE_1 = require('../assets/images/fallback-image.png');

export const PROJECT_1_IMAGE_2 = require('../assets/images/fallback-image.png');

export const PROJECT_1_IMAGE_3 = require('../assets/images/fallback-image.png');

export const PROJECT_1_IMAGE_4 = require('../assets/images/fallback-image.png');

export const PROJECT_1_IMAGE_5 = require('../assets/images/fallback-image.png');

export const PROJECT_1_IMAGE_6 = require('../assets/images/fallback-image.png');

export const PROJECT_1_CONCEPT_IMG = require('../assets/images/fallback-image.png');

export const PROJECT_1_PROCESS_IMG = require('../assets/images/fallback-image.png');

// ----------- -> Project 2 House in Honor Oak Additional Images <- -----------

export const PROJECT_2_IMAGE_1 = require('../assets/images/Projects/Design_Residential/Extension_in_Honor_Oak/EHO_1.jpg');

export const PROJECT_2_IMAGE_2 = require('../assets/images/Projects/Design_Residential/Extension_in_Honor_Oak/EHO_2.jpg');

export const PROJECT_2_IMAGE_3 = require('../assets/images/Projects/Design_Residential/Extension_in_Honor_Oak/EHO_3.jpg');

export const PROJECT_2_CONCEPT_IMG = require('../assets/images/Projects/Design_Residential/Extension_in_Honor_Oak/EHO_4.jpg');

export const PROJECT_2_PROCESS_IMG = require('../assets/images/Projects/Design_Residential/Extension_in_Honor_Oak/EHO_5.jpg');

// ----------- -> Project 3 Concrete Urban Furniture Additional Images <- -----------

export const PROJECT_3_IMAGE_1 = require('../assets/images/Projects/Design_Concept/Concreation/CC_1.jpg');

export const PROJECT_3_IMAGE_2 = require('../assets/images/Projects/Design_Concept/Concreation/CC_2.jpg');

export const PROJECT_3_IMAGE_3 = require('../assets/images/Projects/Design_Concept/Concreation/CC_3.jpg');

export const PROJECT_3_IMAGE_4 = require('../assets/images/Projects/Design_Concept/Concreation/CC_4.jpg');

export const PROJECT_3_IMAGE_5 = require('../assets/images/Projects/Design_Concept/Concreation/CC_5.jpg');

export const PROJECT_3_IMAGE_6 = require('../assets/images/Projects/Design_Concept/Concreation/CC_6.jpg');

export const PROJECT_3_CONCEPT_IMG = require('../assets/images/Projects/Design_Concept/Concreation/CC_7.jpg');

export const PROJECT_3_PROCESS_IMG = require('../assets/images/Projects/Design_Concept/Concreation/CC_8.jpg');

// ----------- -> Project 4 Bakery Additional Images <- -----------

export const PROJECT_4_IMAGE_1 = require('../assets/images/Projects/Design_Commercial/Bakery_in_Chelsea/BIC_1.jpg');

export const PROJECT_4_IMAGE_2 = require('../assets/images/Projects/Design_Commercial/Bakery_in_Chelsea/BIC_2.jpg');

export const PROJECT_4_IMAGE_3 = require('../assets/images/Projects/Design_Commercial/Bakery_in_Chelsea/BIC_3.jpg');

export const PROJECT_4_IMAGE_4 = require('../assets/images/Projects/Design_Commercial/Bakery_in_Chelsea/BIC_4.jpg');

export const PROJECT_4_IMAGE_5 = require('../assets/images/Projects/Design_Commercial/Bakery_in_Chelsea/BIC_5.jpg');

export const PROJECT_4_IMAGE_6 = require('../assets/images/Projects/Design_Commercial/Bakery_in_Chelsea/BIC_6.jpg');

export const PROJECT_4_CONCEPT_IMG = require('../assets/images/Projects/Design_Commercial/Bakery_in_Chelsea/BIC_7.jpg');

export const PROJECT_4_PROCESS_IMG = require('../assets/images/Projects/Design_Commercial/Bakery_in_Chelsea/BIC_8.jpg');

// ----------- -> Project 5 House in Hampstead Additional Images <- -----------

export const PROJECT_5_IMAGE_1 = require('../assets/images/Projects/Design_Residential/Extension_in_Hampstead_Heath/EHH_1.jpg');

export const PROJECT_5_IMAGE_2 = require('../assets/images/Projects/Design_Residential/Extension_in_Hampstead_Heath/EHH_2.jpg');

export const PROJECT_5_IMAGE_3 = require('../assets/images/Projects/Design_Residential/Extension_in_Hampstead_Heath/EHH_3.jpg');

export const PROJECT_5_IMAGE_4 = require('../assets/images/Projects/Design_Residential/Extension_in_Hampstead_Heath/EHH_4.jpg');

export const PROJECT_5_IMAGE_5 = require('../assets/images/Projects/Design_Residential/Extension_in_Hampstead_Heath/EHH_5.jpg');

export const PROJECT_5_IMAGE_6 = require('../assets/images/Projects/Design_Residential/Extension_in_Hampstead_Heath/EHH_6.jpg');

export const PROJECT_5_CONCEPT_IMG = require('../assets/images/Projects/Design_Residential/Extension_in_Hampstead_Heath/EHH_7.jpg');

export const PROJECT_5_PROCESS_IMG = require('../assets/images/Projects/Design_Residential/Extension_in_Hampstead_Heath/EHH_8.jpg');

// ----------- -> Project 6 China Wine Cultural Centre Additional Images <- -----------

export const PROJECT_6_IMAGE_1 = require('../assets/images/Projects/Design_Concept/Wine_Cultural_Centre/WCC_1.jpg');

export const PROJECT_6_IMAGE_2 = require('../assets/images/Projects/Design_Concept/Wine_Cultural_Centre/WCC_2.jpg');

export const PROJECT_6_IMAGE_3 = require('../assets/images/Projects/Design_Concept/Wine_Cultural_Centre/WCC_3.jpg');

export const PROJECT_6_CONCEPT_IMG = require('../assets/images/Projects/Design_Concept/Wine_Cultural_Centre/WCC_4.jpg');

export const PROJECT_6_PROCESS_IMG = require('../assets/images/Projects/Design_Concept/Wine_Cultural_Centre/WCC_5.jpg');

// ----------- -> Project 7 Dental Practice Blackheath Additional Images <- -----------

export const PROJECT_7_IMAGE_1 = require('../assets/images/Projects/Design_Commercial/Blackheath_Dental_Practice/BDP_1.jpg');

export const PROJECT_7_IMAGE_2 = require('../assets/images/Projects/Design_Commercial/Blackheath_Dental_Practice/BDP_2.jpg');

export const PROJECT_7_IMAGE_3 = require('../assets/images/Projects/Design_Commercial/Blackheath_Dental_Practice/BDP_3.jpg');

export const PROJECT_7_IMAGE_4 = require('../assets/images/Projects/Design_Commercial/Blackheath_Dental_Practice/BDP_4.jpg');

export const PROJECT_7_IMAGE_5 = require('../assets/images/Projects/Design_Commercial/Blackheath_Dental_Practice/BDP_5.jpg');

export const PROJECT_7_IMAGE_6 = require('../assets/images/Projects/Design_Commercial/Blackheath_Dental_Practice/BDP_6.jpg');

export const PROJECT_7_CONCEPT_IMG = require('../assets/images/Projects/Design_Commercial/Blackheath_Dental_Practice/BDP_7.jpg');

export const PROJECT_7_PROCESS_IMG = require('../assets/images/Projects/Design_Commercial/Blackheath_Dental_Practice/BDP_8.jpg');

// ----------- -> Project 8 House in Bromley Additional Images <- -----------

export const PROJECT_8_IMAGE_1 = require('../assets/images/Projects/Design_Residential/Extension_in_Beckenham/EB_1.jpg');

export const PROJECT_8_IMAGE_2 = require('../assets/images/Projects/Design_Residential/Extension_in_Beckenham/EB_2.jpg');

export const PROJECT_8_IMAGE_3 = require('../assets/images/Projects/Design_Residential/Extension_in_Beckenham/EB_3.jpg');

export const PROJECT_8_IMAGE_4 = require('../assets/images/Projects/Design_Residential/Extension_in_Beckenham/EB_4.jpg');

export const PROJECT_8_IMAGE_5 = require('../assets/images/Projects/Design_Residential/Extension_in_Beckenham/EB_5.jpg');

export const PROJECT_8_IMAGE_6 = require('../assets/images/Projects/Design_Residential/Extension_in_Beckenham/EB_6.jpg');

export const PROJECT_8_CONCEPT_IMG = require('../assets/images/Projects/Design_Residential/Extension_in_Beckenham/EB_7.jpg');

export const PROJECT_8_PROCESS_IMG = require('../assets/images/Projects/Design_Residential/Extension_in_Beckenham/EB_8.jpg');

// ----------- -> Project 9 Competition Mining China Additional Images <- -----------

export const PROJECT_9_IMAGE_1 = require('../assets/images/Projects/Design_Concept/Refold_Our_Past_Retold/ROPR_1.jpg');

export const PROJECT_9_IMAGE_2 = require('../assets/images/Projects/Design_Concept/Refold_Our_Past_Retold/ROPR_2.jpg');

export const PROJECT_9_IMAGE_3 = require('../assets/images/Projects/Design_Concept/Refold_Our_Past_Retold/ROPR_3.jpg');

export const PROJECT_9_CONCEPT_IMG = require('../assets/images/Projects/Design_Concept/Refold_Our_Past_Retold/ROPR_4.jpg');

export const PROJECT_9_PROCESS_IMG = require('../assets/images/Projects/Design_Concept/Refold_Our_Past_Retold/ROPR_5.jpg');

// ----------- -> Project 10 Vet Shop Additional Images <- -----------

export const PROJECT_10_IMAGE_1 = require('../assets/images/Projects/Design_Commercial/Veterinary_Shop_and_Clinic/VSC_1.jpg');

export const PROJECT_10_IMAGE_2 = require('../assets/images/Projects/Design_Commercial/Veterinary_Shop_and_Clinic/VSC_2.jpg');

export const PROJECT_10_IMAGE_3 = require('../assets/images/Projects/Design_Commercial/Veterinary_Shop_and_Clinic/VSC_3.jpg');

export const PROJECT_10_IMAGE_4 = require('../assets/images/Projects/Design_Commercial/Veterinary_Shop_and_Clinic/VSC_4.jpg');

export const PROJECT_10_IMAGE_5 = require('../assets/images/Projects/Design_Commercial/Veterinary_Shop_and_Clinic/VSC_5.jpg');

export const PROJECT_10_IMAGE_6 = require('../assets/images/Projects/Design_Commercial/Veterinary_Shop_and_Clinic/VSC_6.jpg');

export const PROJECT_10_CONCEPT_IMG = require('../assets/images/Projects/Design_Commercial/Veterinary_Shop_and_Clinic/VSC_7.jpg');

export const PROJECT_10_PROCESS_IMG = require('../assets/images/Projects/Design_Commercial/Veterinary_Shop_and_Clinic/VSC_8.jpg');