import {
  DESIGN_CARD_IMG,
  BIODESIGN_CARD_IMG,
  AUTOMATION_CARD_IMG,
  MAPPING_CARD_IMG,
  LOGO_DESIGN,
  LOGO_MAPPING,
  LOGO_AUTOMATION,
  LOGO_BIODESIGN,
} from '../constants/ImagePaths';
import { Expertise } from '../models/Team';

export const expertiseCards: Expertise[] = [
  {
    id: 0,
    title: 'Design',
    description:
      "We strive to create functional, sustainable, and inspiring spaces that enhance people's lives. With experience in small-scale residential and commercial projects, we are dedicated to understanding and addressing our clients' requirements through a systematic and transparent approach.",
    image: DESIGN_CARD_IMG,
    cover: true,
    lg: LOGO_DESIGN,
  },
  {
    id: 1,
    title: '3D Mapping',
    description:
      "Our licensed team of designers are able to provide 3D mapping services of exterior building envelopes and landscapes, delivering digital scans and topographical data. The integration of this service within the design process significantly enhances project workflow efficiency.",
    image: MAPPING_CARD_IMG,
    lg: LOGO_MAPPING,
  },
  {
    id: 2,
    title: 'Automation',
    description:
      "Through automation, we aim to deliver efficient and adaptable architectural solutions that meet client and environmental needs. As an additional service, we offer the incorporation of smart technologies within our projects which optimizes energy use, creating responsive and sustainable spaces.",
    image: AUTOMATION_CARD_IMG,
    lg: LOGO_AUTOMATION,
  },
  {
    id: 3,
    title: 'Biodesign',
    description:
      "Biodesign in architecture involves the integration of biological processes derived from living organisms into sustainable construction materials and systems. By adopting a multidisciplinary approach towards architectural design, arch4D aims to provide innovative design solutions in the future through research and collaborations.",
    image: BIODESIGN_CARD_IMG,
    lg: LOGO_BIODESIGN,
  },
];
