import React, { useEffect, useMemo, useRef, useState } from 'react';

import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  IconButton,
  SkipNavLink,
  SlideFade,
  useMediaQuery,
} from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';

import LOGO_BLACK from '../assets/images/Assets/Logos/logo-classic-black.png';
import LOGO_WHITE from '../assets/images/Assets/Logos/logo-classic-white.png';
import { getCurrentDimension } from '../constants/Utils';
import { useHeader } from '../contexts/useHeader';
import { NavigationLink } from '../models/Header';

import { NavLink, Logo, Spacer } from '.';

interface HeaderProps {
  links: NavigationLink[];
  justLightMode?: boolean;
}

const Header = ({ links, justLightMode = false }: HeaderProps) => {
  const { setHeaderDimensions } = useHeader();
  const headerRef = useRef(null);
  const dimensions = useSize(headerRef);

  const [isMobile] = useMediaQuery('(max-width: 767px)');

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [positionTop, setPositionTop] = useState<number>(0);
  const [showMobileView, setShowMobileView] = useState<boolean>(false);

  const darkMode = useMemo<boolean>(
    () =>
      justLightMode
        ? false
        : !!dimensions &&
          positionTop <= screenSize.height - dimensions.height / 2,
    [dimensions, positionTop, screenSize, justLightMode]
  );

  const headerHeight = useMemo<number>(() => window.innerHeight, []);

  const handleScroll = () => {
    const position = window.scrollY;
    setPositionTop(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (!!dimensions) {
      setHeaderDimensions(dimensions);
    }
  }, [dimensions]);

  return (
    <>
      <Flex
        id={'header'}
        ref={headerRef}
        minWidth={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        bgGradient={
          darkMode
            ? 'linear-gradient(180deg, rgba(0, 0, 0) 20%, rgba(0, 0, 0, 0.85) 35%, rgba(0, 0, 0, 0.60) 55%, rgba(0, 0, 0, 0.00) 100%)'
            : 'linear-gradient(180deg, rgba(255, 255, 255) 25%, rgba(255, 255, 255, 0.85) 65%, rgba(255, 255, 255, 0.60) 85%, rgba(255, 255, 255, 0.00) 100%);'
        }
        position={'sticky'}
        top={0}
        left={0}
        right={0}
        marginBottom={`-${dimensions?.height}px`}
        zIndex={30}
      >
        <Box width={{ base: '100%', md: '80%' }}>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            paddingX={{ base: '20px', md: 0 }}
          >
            <Logo
              color={darkMode ? 'white' : 'black'}
              logoPath={darkMode ? LOGO_WHITE : LOGO_BLACK}
              size={isMobile ? 60 : 100}
            />
            <Flex flex={1} justifyContent={'flex-end'}>
              {isMobile ? (
                <>
                  <IconButton
                    variant={'link'}
                    aria-label="Open mobile menu"
                    icon={<HamburgerIcon boxSize={30} />}
                    color={darkMode ? 'white' : 'black'}
                    size={'md'}
                    onClick={() => setShowMobileView(true)}
                  />
                </>
              ) : (
                <>
                  <SkipNavLink />
                  {links.map((link) => (
                    <NavLink
                      key={`${link.id}-${link.title}`}
                      link={link}
                      color={darkMode ? 'white' : 'black'}
                    />
                  ))}
                </>
              )}
            </Flex>
          </Flex>
        </Box>
      </Flex>
      {showMobileView && (
        <SlideFade in={showMobileView} offsetY={'-100px'}>
          <IconButton
            position={'absolute'}
            top={`${positionTop + 20}px`}
            right={'20px'}
            variant={'link'}
            aria-label="Close mobile menu"
            icon={<CloseIcon boxSize={'30px'} />}
            color={'white'}
            size={'md'}
            onClick={() => setShowMobileView(false)}
            zIndex={400}
          />
          <Box
            position={'absolute'}
            top={`${positionTop}px`}
            left={0}
            width={'100%'}
            height={headerHeight}
            zIndex={300}
            backgroundColor={'rgba(0, 0, 0, 0.85)'}
          >
            <Flex
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              height={'100%'}
            >
              {links.map((link) => (
                <>
                  <NavLink
                    key={`${link.id}-${link.title}-mobile`}
                    link={link}
                    color={'white'}
                    onClick={() => setShowMobileView(false)}
                  />
                  <Spacer size={30} />
                </>
              ))}
            </Flex>
          </Box>
        </SlideFade>
      )}
    </>
  );
};

export default Header;
