import React, { useState } from 'react';

import {
  Box,
  Center,
  Fade,
  Flex,
  SlideFade,
  useMediaQuery,
} from '@chakra-ui/react';

import { Text } from '../../../components';
import { TextType } from '../../../models/Text';

interface ExpertiseProps {
  bgUri: string;
  title: string;
  description: string;
  ico?: string;
}

const ExpertiseAdditionalServiceCard = ({
  bgUri,
  title,
  description,
  ico,

}: ExpertiseProps) => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <Box
      aspectRatio={1}
      height={isMobile ? 'unset' : '100%'}
      width={isMobile ? '100%' : 'unset'}
      marginY={isMobile ? '5px' : 0}
      bgImage={`url(${bgUri})`}
      backgroundSize={'cover'}
      backgroundPosition={'center'}
      onMouseOver={() => setShowDetails(true)}
      onMouseOut={() => setShowDetails(false)}
    >
      <Flex
        flex={1}
        width={'100%'}
        height={'100%'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        bgGradient={
          showDetails
            ? `linear-gradient(180deg, rgba(53, 53, 53, 0.00) 0%, #353535 75%)`
            : `linear-gradient(180deg, rgba(53, 53, 53, 0.00) 65%, #353535 100%);`
        }
        padding={'20px'}
      >
        {showDetails ? (
          <Flex
            flex={1}
            aspectRatio={1}
            height={'100%'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Fade in={showDetails} style={{ height: '100%' }}>
              <Center height={'100%'}>
                <Box
                  padding={0}
                  aspectRatio={1}
                  height={'50%'}
                  backgroundImage={`url(${ico})`}
                  backgroundSize={'contain'}
                  backgroundPosition={'center'}
                />
              </Center>
            </Fade>
            <SlideFade in={showDetails}>
              <Center>
                <Text type={TextType.Inter20Regular} color="white">
                  {title.toUpperCase()}
                </Text>
              </Center>
              <Box
                borderTopColor="white"
                borderTopWidth={'1px'}
                marginY={'16px'}
                width={'100%'}
              />
              <Text type={{ base: TextType.Inter12Light, md: TextType.Inter14Light }} color="white">
                {description}
              </Text>
            </SlideFade>
          </Flex>
        ) : (
          <Center marginBottom={'20px'}>
            <Text type={TextType.Inter20Regular} color="white">
              {title.toUpperCase()}
            </Text>
          </Center>
        )}
      </Flex>
    </Box>
  );
};

export default ExpertiseAdditionalServiceCard;
