import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { Text } from '../components';
import { TextType } from '../models/Text';

interface SectionTitleProps {
  id?: string;
  title: string;
  description?: string;
  description2?: string;
  description3?: string;
  description4?: string;
  centered?: boolean;
  noPadding?: boolean;
}

const SectionTitle = ({
  id,
  title,
  description,
  description2,
  centered = false,
  noPadding = false,
}: SectionTitleProps) => {
  return (
    <Box
      id={id}
      width={{ base: '100%', md: '80%' }}
      marginY={{ base: '20px', md: '40px' }}
      paddingX={{ base: noPadding ? 0 : '20px', md: 0 }}
    >
      <Flex
        justifyContent={'center'}
        alignItems={centered ? 'center' : 'flex-start'}
        flexDirection={'column'}
      >
        <Text
          type={{ base: TextType.Inter18Bold, md: TextType.Inter20Bold }}
          color="gray.500"
          others={{ marginBottom: '12px' }}
        >
          {title.toUpperCase()}
        </Text>
        <Text
          type={{ base: TextType.Inter12Light, md: TextType.Inter16Light }}
          color="gray.500"
          others={{ textAlign: 'justify' }}
        >
          <p>{description}</p>
          &nbsp;
          <p>{description2}</p>
        </Text>
      </Flex>
    </Box>
  );
};

export default SectionTitle;
