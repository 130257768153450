import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  FilterChips,
  Footer,
  Header,
  PageWrapper,
  ProjectsGalleryWithPagination,
  SectionTitle,
  Spacer,
  Text,
} from '../../components';
import { Filter } from '../../components/FilterChips';
import { useHeader } from '../../contexts/useHeader';
import { projectsLinks } from '../../data/mock.data.projects';
import { projects as projectsData } from '../../data/mock.data.projects';
import { Project, ProjectsTypes } from '../../models/Project';

const ProjectsPage = () => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const { headerDimensions } = useHeader();

  const projectFilters = useMemo<Filter[]>(
    () => [
      {
        label: t('projects.filters.commercial'),
        value: ProjectsTypes.COMMERCIAL,
        selected: false,
      },
      {
        label: t('projects.filters.residential'),
        value: ProjectsTypes.RESIDENTIAL,
        selected: false,
      },
      {
        label: t('projects.filters.concept'),
        value: ProjectsTypes.CONCEPT,
        selected: false,
      },
    ],
    []
  );

  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const handleAddFilter = useCallback((filter: Filter) => {
    setActiveFilters((prev) => [...prev, filter.value]);
  }, []);

  const handleRemoveFilter = useCallback((filter: Filter) => {
    setActiveFilters((prev) => prev.filter((item) => item !== filter.value));
  }, []);

  const filteredProjects = useMemo<Project[]>(
    () =>
      activeFilters.length > 0
        ? projectsData.filter((project) => activeFilters.includes(project.type))
        : projectsData,
    [activeFilters]
  );

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Header links={projectsLinks} justLightMode />
      <PageWrapper>
        <Spacer size={headerDimensions?.height || 0} />
        <SectionTitle title={t('projects.title')} />
        <FilterChips
          filters={projectFilters}
          onSelect={handleAddFilter}
          onRemove={handleRemoveFilter}
          multi
        />
        <Spacer mobileSize={30} size={60} />
        {filteredProjects.length > 0 ? (
          <ProjectsGalleryWithPagination
            items={filteredProjects}
            buttonLabel={
              isMobile ? t('projects.loadMoreBtn') : t('projects.backBtn')
            }
          />
        ) : (
          <Text>No projects matching your filters...</Text>
        )}
        <Spacer mobileSize={30} size={40} />
        <Footer />
      </PageWrapper>
    </>
  );
};

export default ProjectsPage;
