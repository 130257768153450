import React, { useCallback, useMemo } from 'react';

import { Center, LinkOverlay, LinkBox } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useNavigation } from '../contexts/useNavigation';
import { NavigationLink } from '../models/Header';
import { TextType } from '../models/Text';

import Text from './Text';

interface NavLinkProps {
  link: NavigationLink;
  color?: string;
  onClick?: () => void;
}

const NavLink = ({
  link,
  color = 'white',
  onClick = () => null,
}: NavLinkProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setScrollToAnchor } = useNavigation();

  const selectedLink = useMemo<boolean>(
    () => pathname === link.linkTo,
    [pathname, link.linkTo]
  );

  const handleClick = useCallback(() => {
    if (link.tag) {
      navigate(link.linkTo);
      setScrollToAnchor(link.tag);
    }

    onClick?.();
  }, [onClick, setScrollToAnchor, navigate]);

  return (
    <Center paddingX={'12px'} paddingY={'10px'} marginX={'12px'}>
      <LinkBox>
        <Text
          type={
            selectedLink ? TextType.Inter12BoldNav : TextType.Inter12LightNav
          }
          others={{ textTransform: 'lowercase' }}
          color={color}
        >
          <LinkOverlay
            href={link.tag ? undefined : link.linkTo}
            textUnderlineOffset={0}
            onClick={handleClick}
          >
            {t(`header.${link.title}`)}
          </LinkOverlay>
        </Text>
      </LinkBox>
    </Center>
  );
};

export default NavLink;
