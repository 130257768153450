import React from 'react';

import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ImageComponent, PageWrapper, SectionTitle } from '../../../components';
import {
  HOW_WE_WORK_IMG,
  HOW_WE_WORK_IMG_MOBILE,
} from '../../../constants/ImagePaths';

interface HowWeWorkSectionProps {
  marginTop?: number;
}

const HowWeWorkSection = ({ marginTop = 0 }: HowWeWorkSectionProps) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 767px)');

  return (
    <PageWrapper id="sections-2">
      <Box paddingTop={`${marginTop}px`} />
      <SectionTitle
        title={t('homepage.howWeWork.title')}
        description={t('homepage.howWeWork.description')}
      />
      <Flex flex={1} width={'100%'}>
        <ImageComponent
          src={isMobile ? HOW_WE_WORK_IMG_MOBILE : HOW_WE_WORK_IMG}
          altMessage="How we work"
          others={{
            objectFit: 'contain',
            width: '100%',
          }}
        />
      </Flex>
    </PageWrapper>
  );
};

export default HowWeWorkSection;
