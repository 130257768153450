import { EMP_1, EMP_2, EMP_3, EMP_4, EMP_5, EMP_6 } from '../constants/ImagePaths';

import { NavigationLink } from './../models/Header';
import { TeamData } from './../models/Team';

export const aboutLinks: NavigationLink[] = [
  {
    id: 0,
    title: 'about',
    linkTo: '/about',
  },
  {
    id: 1,
    title: 'expertise',
    linkTo: '/#expertise',
  },
  {
    id: 2,
    title: 'projects',
    linkTo: '/projects',
  },
  // {
  //   id: 3,
  //   title: 'journal',
  //   linkTo: '/journal',
  // },
  {
    id: 3,
    title: 'contact',
    linkTo: '/#contact',
  },
];

export const teamData: TeamData[] = [
  {
    name: 'Lucian Mocanu ',
    role: 'Founder & Director',
    image: EMP_1,
    about: 'BArch, MArch',
  },
  {
    name: 'Philia Chua',
    role: 'Associate Director',
    image: EMP_2,
    about: 'BArch, MArch, MArch in Bio-ID'
  },
  {
    name: 'Stefan Mocanu',
    role: 'Administrator & Project Manager',
    image: EMP_3,
    about: 'BEng, MEng'
  },
  {
    name: 'Mihai Visinescu',
    role: 'CAD Manager',
    image: EMP_4,
    about: 'Central Europe, Collaborator'
  },
  {
    name: 'Madalin Petculescu',
    role: 'Project Manager',
    image: EMP_5,
    about: 'Eastern Europe Collaborator, Arc Bright Mark'
  },
  {
    name: 'Gary Yeow',
    role: 'Project Manager',
    image: EMP_6,
    about: 'South-East Asia Collaborator, Unagru'
  },
];
