import React, { useState } from 'react';

import { Box, Flex, SlideFade } from '@chakra-ui/react';

interface MemberCardProps {
  heroImage?: string;
  withShadow?: boolean;
  centered?: boolean;
  children: React.ReactNode;
}

const MemberCard = ({
  heroImage,
  withShadow = false,
  centered = false,
  children,
}: MemberCardProps) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <Box
      width={'100%'}
      height={'100%'}
      bgImage={heroImage || require('../assets/images/fallback-image.png')}
      backgroundSize={'cover'}
      backgroundPosition={'center'}
      onMouseOver={() => setShowDetails(true)}
      onMouseOut={() => setShowDetails(false)}
    >
      <Flex
        flex={1}
        height={'100%'}
        justifyContent={'center'}
        alignItems={centered ? 'center' : 'flex-end'}
        bgGradient={
          showDetails
            ? `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 20%, #ffffff 95%)`
            : `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 100%, #ffffff 100%);`
        }
      >
        <SlideFade in={showDetails} offsetY={'30px'}>
          {children}
        </SlideFade>
      </Flex>
    </Box>
  );
};

export default MemberCard;
