import React from 'react';

import { Outlet } from 'react-router-dom';

import ScrollToTop from '../../navigation/components/ScrollToTop';

const Layout = () => {
  return (
    <>
      <Outlet />
      <ScrollToTop />
    </>
  );
};

export default Layout;
