import React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import ErrorPage404 from '../pages/404';
import AboutPage from '../pages/about';
import HomePage from '../pages/homepage';
import Layout from '../pages/layout/Layout';
import PrivacyPolicyPage from '../pages/privacyPolicy';
import ProjectDetailsPage from '../pages/projectDetails';
import ProjectsPage from '../pages/projects';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage404 />,
    children: [
      { path: '', element: <HomePage />, index: true },
      {
        path: 'about',
        element: <Layout />,
        children: [
          {
            path: '',
            element: <AboutPage />,
            index: true,
          },
        ],
      },
      {
        path: 'projects',
        element: <Layout />,
        children: [
          { path: '', element: <ProjectsPage />, index: true },
          {
            path: ':id',
            element: <Layout />,
            children: [
              {
                path: '',
                element: <ProjectDetailsPage />,
                index: true,
              },
            ],
          },
        ],
      },
      {
        path: 'privacyPolicy',
        element: <Layout />,
        children: [
          {
            path: '',
            element: <PrivacyPolicyPage />,
            index: true,
          },
        ],
      },
    ],
  },
]);

export default router;
