export enum TextType {
  Inter8Light = 'inter8Light',
  Inter8Regular = 'inter8Regular',
  Inter8Bold = 'inter8Bold',
  Inter9Light = 'inter9Light',
  Inter9Regular = 'inter9Regular',
  Inter9Bold = 'inter9Bold',
  Inter10Light = 'inter10Light',
  Inter10Regular = 'inter10Regular',
  Inter10Bold = 'inter10Bold',
  Inter12LightNav = 'inter12LightNav',
  Inter12BoldNav = 'inter12BoldNav',
  Inter12Light = 'inter12Light',
  Inter12Regular = 'inter12Regular',
  Inter12Bold = 'inter12Bold',
  Inter14Light = 'inter14Light',
  Inter14Regular = 'inter14Regular',
  Inter14Bold = 'inter14Bold',
  Inter16Light = 'inter16Light',
  Inter16Regular = 'inter16Regular',
  Inter16Bold = 'inter16Bold',
  Inter18Light = 'inter18Light',
  Inter18Regular = 'inter18Regular',
  Inter18Bold = 'inter18Bold',
  Inter20Light = 'inter20Light',
  Inter20Regular = 'inter20Regular',
  Inter20Bold = 'inter20Bold',
  Inter32Light = 'inter32Light',
  Inter32Regular = 'inter32Regular',
  Inter32Bold = 'inter32Bold',
  Inter60Bold = 'inter60Bold',
  Inter96Bold = 'inter96Bold',
}
