import { Location } from '../models/Location';

export const locations: Location[] = [
  {
    name: 'London',
    contact: {
      email: 'info@arch4d.co.uk',
      phoneNumber: '+44 (0)20 3856 7946',
    },
  },
  {
    name: 'Bucharest',
    contact: {
      email: 'info@arch4d.ro',
      phoneNumber: '+40 (0)724 841 897',
    },
  },
  {
    name: 'Kuala Lumpur',
    contact: {
      email: 'info@arch4d.my',
      phoneNumber: '+60 (0)199 023 757',
    },
  },
];
