import React, { useCallback, useMemo } from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Spinner,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import {
  Footer,
  Header,
  ImageComponent,
  PageWrapper,
  Spacer,
  Text,
} from '../../components';
import { PageAlignment } from '../../components/PageWrapper';
import { useHeader } from '../../contexts/useHeader';
import { projects, projectsLinks } from '../../data/mock.data.projects';
import { Project } from '../../models/Project';
import { TextType } from '../../models/Text';

const ProjectDetailsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const { headerDimensions } = useHeader();

  const project = useMemo<Project | null>(
    () =>
      id != null
        ? projects.filter((project) => project.id === parseInt(id))[0] || null
        : null,
    [id]
  );

  const handleSeeDetails = () => {
    const element = document.getElementById('projectDetails');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClickButton = useCallback(() => {
    navigate('/projects');
  }, []);

  const renderDetail = (label: string, value: string) => (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Text type={{ base: TextType.Inter12Bold, md: TextType.Inter14Bold }}>
        {label.toUpperCase()}
      </Text>
      <Spacer mobileSize={6} size={12} />
      <Text type={TextType.Inter12Light}>{value}</Text>
      <Spacer mobileSize={30} size={0} />
    </Flex>
  );

  const renderSection = (
    title: string,
    description: string,
    image?: string,
    reverse: boolean = false
  ) => {
    return (
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={{
          base: 'column-reverse',
          md: reverse ? 'row-reverse' : 'row',
        }}
      >
        <Flex
          flex={1}
          height={'100%'}
          width={{ base: '100%', md: 'auto' }}
          flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          marginRight={{ base: 0, md: reverse ? 0 : '60px' }}
          marginLeft={{ base: 0, md: reverse ? '60px' : 0 }}
          paddingX={{ base: '20px', md: 0 }}
        >
          <Text
            type={{ base: TextType.Inter12Bold, md: TextType.Inter14Bold }}
            others={{
              marginTop: { base: '20px', md: 0 },
            }}
          >
            {title.toUpperCase()}
          </Text>
          <Spacer mobileSize={10} size={20} />
          <Text
            type={{ base: TextType.Inter12Light, md: TextType.Inter14Light }}
            others={{
              textAlign: 'justify',
            }}
          >
            {description}
          </Text>
        </Flex>
        <Box maxWidth={{ base: '100%', md: '60%' }}>
          <ImageComponent
            altMessage={'Project concept'}
            src={image}
            others={{
              aspectRatio: { base: 1, md: 16 / 9 },
            }}
          />
        </Box>
      </Flex>
    );
  };

  if (project == null) {
    return (
      <PageWrapper>
        <Text
          type={TextType.Inter14Bold}
          others={{
            marginBottom: '12px',
          }}
        >
          {`No details found about this project. :(`}
        </Text>
        <Spacer size={12} />
        <Text type={TextType.Inter12Regular}>Please try again...</Text>
      </PageWrapper>
    );
  }

  return (
    <>
      <Header links={projectsLinks} />
      <PageWrapper pageAlignment={PageAlignment.END} bgUri={project.heroImage}>
        <Text
          type={TextType.Inter18Bold}
          color="white"
          others={{
            marginBottom: { base: '10px', md: '40px' },
          }}
        >
          {project.title.toUpperCase()}
        </Text>
        {isMobile && (
          <IconButton
            variant={'link'}
            aria-label="Scroll down to see the content"
            icon={<ChevronDownIcon boxSize={50} />}
            color={'white'}
            size={'md'}
            marginBottom={'10px'}
            onClick={handleSeeDetails}
          />
        )}
      </PageWrapper>
      <PageWrapper pageAlignment={PageAlignment.START} id={'projectDetails'}>
        <Box width={{ base: '100%', md: '80%' }} marginTop={'20px'}>
          <Spacer size={headerDimensions?.height} />
          <Flex
            width={'100%'}
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={'center'}
            justifyContent={'space-between'}
            paddingX={{ base: '20px', md: 0 }}
          >
            {renderDetail(t('projectDetails.category'), project.category)}
            {renderDetail(t('projectDetails.client'), project.client)}
            {renderDetail(t('projectDetails.location'), project.location.name)}
            {renderDetail(t('projectDetails.year'), project.year)}
            {project?.collabs &&
              renderDetail(
                t('projectDetails.collab'),
                project.collabs.map((collab) => collab.toUpperCase()).join(' ')
              )}
          </Flex>
          <Spacer mobileSize={30} size={60} />
          {project.concept &&
            renderSection(
              t('projectDetails.conceptSection'),
              project.concept.description,
              project.concept.image
            )}
          <Spacer mobileSize={40} size={60} />
          {project.process &&
            renderSection(
              t('projectDetails.processSection'),
              project.process.description,
              project.process.image,
              true
            )}
        </Box>
        <Spacer mobileSize={60} size={headerDimensions?.height} />
        {project.images && project.images.length > 0 && (
          <Grid
            flex={1}
            width={'100%'}
            templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}
            gap={{ base: '8px', lg: '25px' }}
          >
            {project.images.map((image, index) => (
              <GridItem
                key={`${project.title}-image-${index}`}
                w="100%"
                aspectRatio={1}
              >
                <Box
                  bgImage={image}
                  width={'100%'}
                  height={'100%'}
                  backgroundSize={'cover'}
                  backgroundPosition={'center'}
                ></Box>
              </GridItem>
            ))}
          </Grid>
        )}
        <Spacer mobileSize={30} size={40} />
        <Button
          variant="solid"
          bgColor={'primary'}
          marginTop={{ base: 0, md: '30px' }}
          width={{ base: '50%', md: '33.3333%' }}
          borderRadius={0}
          spinner={
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="secondary"
            />
          }
          onClick={handleClickButton}
        >
          <Text
            type={{ base: TextType.Inter12Bold, md: TextType.Inter14Bold }}
            color="white"
          >
            {t('projectDetails.backButton').toUpperCase()}
          </Text>
        </Button>
        <Spacer mobileSize={30} size={40} />
        <Footer />
      </PageWrapper>
    </>
  );
};

export default ProjectDetailsPage;
