import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  Header,
  PageWrapper,
  SectionTitle,
  Spacer,
  Text,
} from '../../components';
import { PageAlignment } from '../../components/PageWrapper';
import { useHeader } from '../../contexts/useHeader';
import { privacyLinks } from '../../data/mock.data.privacy';
import { privacySections } from '../../data/mock.data.privacy';
import { TextType } from '../../models/Text';

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();
  const { headerDimensions } = useHeader();

  return (
    <>
      <Header links={privacyLinks} justLightMode />
      <PageWrapper pageAlignment={PageAlignment.START}>
        <Spacer size={headerDimensions?.height || 0} />
        
        <Box
          width={{ base: '100%', md: '80%' }}
          paddingX={{ base: '20px', md: 0 }}
        >
          <iframe src="https://www.iubenda.com/privacy-policy/73593328/legal?an=no&s_ck=false&newmarkup=yes" width={'100%'} height={4500}></iframe>
        </Box>  
      </PageWrapper>
    </>
  );
};

export default PrivacyPolicyPage;
