import React, { ReactNode } from 'react';

import {
  Text as ChakraText,
  ResponsiveObject,
  StyleProps,
} from '@chakra-ui/react';

import { TextType } from '../models/Text';

interface TextComponentProps {
  type?: TextType | ResponsiveObject<TextType>;
  color?: string;
  others?: StyleProps;
  children: ReactNode;
}

const Text = ({
  type = {
    base: TextType.Inter14Light,
    md: TextType.Inter14Regular,
  },
  color = 'gray.500',
  children,
  others,
}: TextComponentProps) => {
  return (
    <ChakraText textStyle={type} color={color} {...others}>
      {children}
    </ChakraText>
  );
};

export default Text;
