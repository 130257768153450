import {
  FALLBACK_IMAGE,
  PROJECT_2_HERO_IMAGE, PROJECT_3_HERO_IMAGE, PROJECT_4_HERO_IMAGE, PROJECT_5_HERO_IMAGE, PROJECT_6_HERO_IMAGE, PROJECT_7_HERO_IMAGE, PROJECT_8_HERO_IMAGE, PROJECT_9_HERO_IMAGE, PROJECT_10_HERO_IMAGE,
  PROJECT_2_CONCEPT_IMG, PROJECT_3_CONCEPT_IMG,PROJECT_4_CONCEPT_IMG, PROJECT_5_CONCEPT_IMG, PROJECT_6_CONCEPT_IMG, PROJECT_7_CONCEPT_IMG, PROJECT_8_CONCEPT_IMG, PROJECT_9_CONCEPT_IMG, PROJECT_10_CONCEPT_IMG,
  PROJECT_2_PROCESS_IMG, PROJECT_3_PROCESS_IMG,PROJECT_4_PROCESS_IMG, PROJECT_5_PROCESS_IMG, PROJECT_6_PROCESS_IMG, PROJECT_7_PROCESS_IMG, PROJECT_8_PROCESS_IMG, PROJECT_9_PROCESS_IMG, PROJECT_10_PROCESS_IMG,
  PROJECT_10_IMAGE_1, PROJECT_10_IMAGE_2, PROJECT_10_IMAGE_3, PROJECT_10_IMAGE_4, PROJECT_10_IMAGE_5, PROJECT_10_IMAGE_6,
  PROJECT_9_IMAGE_1, PROJECT_9_IMAGE_2, PROJECT_9_IMAGE_3,
  PROJECT_8_IMAGE_1, PROJECT_8_IMAGE_2, PROJECT_8_IMAGE_3, PROJECT_8_IMAGE_4, PROJECT_8_IMAGE_5, PROJECT_8_IMAGE_6,
  PROJECT_7_IMAGE_1, PROJECT_7_IMAGE_2, PROJECT_7_IMAGE_3, PROJECT_7_IMAGE_4, PROJECT_7_IMAGE_5, PROJECT_7_IMAGE_6,
  PROJECT_6_IMAGE_1, PROJECT_6_IMAGE_2, PROJECT_6_IMAGE_3,
  PROJECT_5_IMAGE_1, PROJECT_5_IMAGE_2, PROJECT_5_IMAGE_3, PROJECT_5_IMAGE_4, PROJECT_5_IMAGE_5, PROJECT_5_IMAGE_6,
  PROJECT_4_IMAGE_1, PROJECT_4_IMAGE_2, PROJECT_4_IMAGE_3, PROJECT_4_IMAGE_4, PROJECT_4_IMAGE_5, PROJECT_4_IMAGE_6,
  PROJECT_3_IMAGE_1, PROJECT_3_IMAGE_2, PROJECT_3_IMAGE_3, PROJECT_3_IMAGE_4, PROJECT_3_IMAGE_5, PROJECT_3_IMAGE_6,
  PROJECT_2_IMAGE_1, PROJECT_2_IMAGE_2, PROJECT_2_IMAGE_3,
} from '../constants/ImagePaths';
import { Project, ProjectsTypes } from '../models/Project';

import { NavigationLink } from './../models/Header';

export const projectsLinks: NavigationLink[] = [
  {
    id: 0,
    title: 'about',
    linkTo: '/about',
  },
  {
    id: 1,
    title: 'expertise',
    linkTo: '/#expertise',
  },
  {
    id: 2,
    title: 'projects',
    linkTo: '/projects',
  },
  // {
  //   id: 3,
  //   title: 'journal',
  //   linkTo: '/journal',
  // },
  {
    id: 3,
    title: 'contact',
    linkTo: '/#contact',
  },
];

export const projects: Project[] = [

  {
    id: 2,
    order: 991,
    title: 'House Extension in Honor Oak',
    shortDescription:
      'Cost-effective, space-efficient extension with a Japandi aesthetic on a quiet London Street.'.replace("&apos;", "'"),
    type: ProjectsTypes.RESIDENTIAL,
    heroImage: PROJECT_2_HERO_IMAGE,
    category: 'Residential',
    client: 'Private Client',
    location: {
      name: 'London, United Kigdom',
    },
    year: '2023',
    // OPTIONAL PROPS
    collabs: ['Build & Developments London'],
    concept: {
      image: PROJECT_2_CONCEPT_IMG, // To be added
      description:
        'The design process started with a clear understanding of the client&apos;s objectives, which included increasing the living room area and creating a dedicated studio space for artwork. To meet these requirements, we created an extension that seamlessly merged the kitchen and lounge areas, providing an open and spacious layout that connected to the rear garden. The challenge of the terraced site required careful planning to ensure that the garden and living space flowed harmoniously.'.replace("&apos;", "'"),
    },
    process: {
      image: PROJECT_2_PROCESS_IMG, // To be added
      description:
        'Collaboration was key in this process, as we engaged in a series of design workshops with the client to discuss various options and ideas. Additionally, we incorporated additional spaces for utility purposes while ensuring efficient circulation throughout the ground floor. Visual representations were also created to showcase the Japandi architectural style envisioned for the project.'.replace("&apos;", "'"),
    },
    images: [
      PROJECT_2_IMAGE_1,
      PROJECT_2_IMAGE_2,
      PROJECT_2_IMAGE_3,
    ],
  },
  {
    id: 3,
    order: 992,
    title: 'Modular Urban Furniture',
    shortDescription:
      'Exploring a plug-and-play concept for modular concrete furniture nestled within undulating landscapes of urban parks'.replace("&apos;", "'"),
    type: ProjectsTypes.CONCEPT,
    heroImage: PROJECT_3_HERO_IMAGE,
    category: 'CONCEPT',
    client: '-',
    location: {
      name: '-',
    },
    year: '2022',
    // OPTIONAL PROPS
    collabs: ['-'],
    concept: {
      image: PROJECT_3_CONCEPT_IMG, // To be added
      description:
        'Among bustling cities and busy intersections, communities gather within urban parks to enjoy the company of family and friends. Soft dunes rise gently among the landscape, providing spaces to rest, cook, eat and play. The proposal embraces the idea of prefabrication as concrete elements are designed with modularity in mind. Three main typologies connect seamlessly with each other, creating endless possibilities to allow the dunes to be reconfigured based on the needs of the community.'.replace("&apos;", "'"),
    },
    process: {
      image: PROJECT_3_PROCESS_IMG, // To be added
      description:
        'The proposal creates an inclusive environment as multiple plug-n-play components can be fitted within the core and outer units. These plug-n-play components include bicycle racks, green slopes, empty platforms, water feature, storage units, BBQ area, tree pits and also a dining table with lazy susan and speaker amplifier. Careful use of concrete is considered, featuring polished concrete, environmentally-friendly bio-crete, patterned concrete and grasscrete. Minimal use of timber and steel components provides comfort and durability around seating areas and bicycle racks.'.replace("&apos;", "'"),
    },
    images: [
      PROJECT_3_IMAGE_1,
      PROJECT_3_IMAGE_2,
      PROJECT_3_IMAGE_3,
      PROJECT_3_IMAGE_4,
      PROJECT_3_IMAGE_5,
      PROJECT_3_IMAGE_6,
    ],
  },
  {
    id: 4,
    order: 993,
    title: 'Bakery in Chelsea',
    shortDescription:
      'Contemporary shopfront with bespoke interior wall panelling, lighting and furniture.'.replace("&apos;", "'"),
    type: ProjectsTypes.COMMERCIAL,
    heroImage: PROJECT_4_HERO_IMAGE,
    category: 'Commercial',
    client: 'Commercial Client',
    location: {
      name: 'London, United Kigdom',
    },
    year: '2022',
    // OPTIONAL PROPS
    collabs: ['KLMAN Architects'],
    concept: {
      image: PROJECT_4_CONCEPT_IMG, // To be added
      description:
        'Assisting KLMAN Architects in the design process and information production, we have realised the vision of the client. We established a new brand identity for their bakery, seamlessly integrating a unified design concept across the interior space and the packaging. A former laundrette was successfully converted into a contemporary dual shopfront.'.replace("&apos;", "'"),
    },
    process: {
      image: PROJECT_4_PROCESS_IMG, // To be added
      description:
        'The unique wall artwork hand-painted by artist Lizzi Porter is inspired by 19th century Parisian wall panelling that interprets a Japanese decorative style. The interior color scheme, table design and part of the fixtures were selected by JR Design. The result is an inviting and exquisitely designed store, which highlights the delightful variety of pastries and chocolates. Construction was realised by Battistel'.replace("&apos;", "'"),
    },
    images: [
      PROJECT_4_IMAGE_1,
      PROJECT_4_IMAGE_2,
      PROJECT_4_IMAGE_3,
      PROJECT_4_IMAGE_4,
      PROJECT_4_IMAGE_5,
      PROJECT_4_IMAGE_6,
    ],
  },
  {
    id: 5,
    order: 994,
    title: 'House Extension in Hampstead',
    shortDescription:
      'Spacious kitchen-dining area and garden-facing living space to accomodate for wider use.'.replace("&apos;", "'"),
    type: ProjectsTypes.RESIDENTIAL,
    heroImage: PROJECT_5_HERO_IMAGE,
    category: 'Residential',
    client: 'Private Client',
    location: {
      name: 'London, United Kigdom',
    },
    year: '2022',
    // OPTIONAL PROPS
    collabs: ['KLMAN Architects'],
    concept: {
      image: PROJECT_5_CONCEPT_IMG, // To be added
      description:
        'As part of the scheme developed by KLMAN architects, we focused on developing the desired look and ambiance of the project. We also produced the information for pre-planning and planning while addressing the complexities of the site. The project involved extending a three-story house located on a sloped site. The kitchen and living area both have limited access to the garden due to being on the lower ground and upper floor respectively.'.replace("&apos;", "'"),
    },
    process: {
      image: PROJECT_5_PROCESS_IMG, // To be added
      description:
        'The extension was designed to create a spacious kitchen-dining area and a garden-facing living space, benefiting from two sizable roof lights for ample natural lighting. The result is a light and spacious family room with generous views to the outdoors. It was carefully planned to maintain a discreet profile in relation to neighbouring properties.'.replace("&apos;", "'"),
    },
    images: [
      PROJECT_5_IMAGE_1,
      PROJECT_5_IMAGE_2,
      PROJECT_5_IMAGE_3,
      PROJECT_5_IMAGE_4,
      PROJECT_5_IMAGE_5,
      PROJECT_5_IMAGE_6,
    ],
  },
  {
    id: 6,
    order: 995,
    title: 'Wine Cultural Center',
    shortDescription:
      'Multifunctional cultural center providing wine-making experiences among the vineyards of Huailai China.'.replace("&apos;", "'"),
    type: ProjectsTypes.CONCEPT,
    heroImage: PROJECT_6_HERO_IMAGE,
    category: 'Concept, Mixed use',
    client: 'Non-profit Organisation',
    location: {
      name: 'Huailai, China',
    },
    year: '2021',
    // OPTIONAL PROPS
    collabs: ['Andrei Ciprian Cojocaru'],
    concept: {
      image: PROJECT_6_CONCEPT_IMG, // To be added
      description:
        'As part of the Crea Talents Architecture Design Competition, we collaborated with Ciprian Cojocaru to design a cultural landmark among the vast open scenery of Huailai. The entry successfully won the &apos;Crea Talents Award&apos;. The Wine Culture Center hovers curiously above the landscape, its simple form inspired by the authority and identity of chinese seals, shaping areas of vineyards within the chosen site. '.replace("&apos;", "'"),
    },
    process: {
      image: PROJECT_6_PROCESS_IMG, // To be added
      description:
        'The building accommodates a range of cultural and commercial functions, including stalls for expos, wine museum, cultural and shopping areas, offices, hotels and a rooftop garden which boasts views across the entire vineyard. With the aim of providing the best experience for users to immerse themselves in the harvesting, producing and tasting of wine, the central Culture Center is accompanied by two separate villages on either side. These villages emerge from the folds of the landscape, providing accommodation for visitors to stay with local winemakers and create memorable experiences by participating in the wine-making process.'.replace("&apos;", "'"),
    },
    images: [
      PROJECT_6_IMAGE_1,
      PROJECT_6_IMAGE_2,
      PROJECT_6_IMAGE_3,
    ],
  },
  {
    id: 7,
    order: 996,
    title: 'Dental practice in Blackheath',
    shortDescription:
      'Transformation of a Grade II listed building into an efficient dental practice space.'.replace("&apos;", "'"),
    type: ProjectsTypes.COMMERCIAL,
    heroImage: PROJECT_7_HERO_IMAGE,
    category: 'Commercial',
    client: 'Commercial Client',
    location: {
      name: 'London, United Kingdom',
    },
    year: '2023',
    // OPTIONAL PROPS
    collabs: ['-'],
    concept: {
      image: PROJECT_7_CONCEPT_IMG, // To be added
      description:
        'Taking on the challenge of renovating a Grade II listed building for a dental practice, we embarked on a design process with a focus on cost-efficiency, building on previous design works carried out by the client. Extensive site surveys and analysis were conducted to create a design that not only complied with local planning policies but also optimized spatial arrangement, effectively integrating dental practice equipment, and meeting rigorous health and safety requirements.'.replace("&apos;", "'"),
    },
    process: {
      image: PROJECT_7_PROCESS_IMG, // To be added
      description:
        'Our approach involved careful coordination with historic consultants and structural engineers to ensure an efficient, straightforward design that also paid careful attention to aesthetics. We also produced a comprehensive set of planning drawings and construction information, ensuring the successful transformation of the historic structure into a functional dental practice space.'.replace("&apos;", "'"),
    },
    images: [
      PROJECT_7_IMAGE_1,
      PROJECT_7_IMAGE_2,
      PROJECT_7_IMAGE_3,
      PROJECT_7_IMAGE_4,
      PROJECT_7_IMAGE_5,
      PROJECT_7_IMAGE_6,
    ],
  },
  {
    id: 8,
    order: 997,
    title: 'House Extension in Beckenham',
    shortDescription:
      'Light and spacious open-plan design with clever storage solutions and feature stairs access.'.replace("&apos;", "'"),
    type: ProjectsTypes.RESIDENTIAL,
    heroImage: PROJECT_8_HERO_IMAGE,
    category: 'Residential',
    client: 'Private Client',
    location: {
      name: 'London, United Kingdom',
    },
    year: '2023',
    // OPTIONAL PROPS
    collabs: ['KLMAN Architects'],
    concept: {
      image: PROJECT_8_CONCEPT_IMG, // To be added
      description:
        'The design of this house extension was driven by the client&apos;s desire to expand and improve the living room and kitchen, while also accommodating a ground floor bedroom for retirement. Assisting KLMAN architects, we contributed to the project by producing planning drawings and visualizing the concept.'.replace("&apos;", "'"),
    },
    process: {
      image: PROJECT_8_PROCESS_IMG, // To be added
      description:
        'To achieve a light and spacious feel, we opted for an open-plan ground floor layout, demarcating spaces using storage units to maintain garden views. This approach allowed for the strategic placement of the kitchen, ensuring both privacy and space for utility needs. Additionally, we transformed the existing spiral staircase into a central feature, creating a circular space around it to enhance the overall brightness and aesthetics of the final design.'.replace("&apos;", "'"),
    },
    images: [
      PROJECT_8_IMAGE_1,
      PROJECT_8_IMAGE_2,
      PROJECT_8_IMAGE_3,
      PROJECT_8_IMAGE_4,
      PROJECT_8_IMAGE_5,
      PROJECT_8_IMAGE_6,
    ],
  },
  {
    id: 9,
    order: 998,
    title: 'Zibo Regeneration Competition',
    shortDescription:
      'Fostering economic growth and environmental sustainability through mixed-use spaces within ecological features.'.replace("&apos;", "'"),
    type: ProjectsTypes.CONCEPT,
    heroImage: PROJECT_9_HERO_IMAGE,
    category: 'Concept, Urban Development',
    client: '-',
    location: {
      name: 'Zibo, China',
    },
    year: '2020',
    // OPTIONAL PROPS
    collabs: ['Andrei Ciprian Cojocaru'],
    concept: {
      image: PROJECT_9_CONCEPT_IMG, // To be added
      description:
        'As part of the &apos;Reviving Mines: Shandong Park Competition&apos; hosted by Non Architecture, we collaborated with Ciprian Cojocaru to propose a landscape regeneration strategy, which received an Honorable Mention. The proposal aims to revitalize the Shandong province by introducing a promenade along existing excavation pits, reconnecting the fragmented landscape, and creating mixed-use architectural spaces to support local economy.'.replace("&apos;", "'"),
    },
    process: {
      image: PROJECT_9_PROCESS_IMG, // To be added
      description:
        'Strips of recreational parks and agro-parks hug the outer rim of the pits, blending seamlessly into the existing residential area below. Along the top of the mountain ridge, there is an ecological strip with tourist platforms and scenic viewpoints, while a protected forest area between this strip and the promenade below fosters a thriving ecosystem connected by forest trails. On the opposite side of the mountain, agricultural terraces on the sloping terrain offer space for food cultivation, habitation, and agro-tourism opportunities.'.replace("&apos;", "'"),
    },
    images: [
      PROJECT_9_IMAGE_1,
      PROJECT_9_IMAGE_2,
      PROJECT_9_IMAGE_3,
    ],
  },
  {
    id: 10,
    order: 999,
    title: 'Vet Clinic and Shop',
    shortDescription:
      'Welcoming and holistic pet care center with bespoke joinery and pod design.'.replace("&apos;", "'"),
    type: ProjectsTypes.COMMERCIAL,
    heroImage: PROJECT_10_HERO_IMAGE,
    category: 'Commercial',
    client: 'Commercial Client',
    location: {
      name: 'Brentwood, United Kingdom',
    },
    year: '2019',
    // OPTIONAL PROPS
    collabs: ['KLMAN Architects'],
    concept: {
      image: PROJECT_10_CONCEPT_IMG, // To be added
      description:
        'We assisted KLMAN Architects in the completion of a comprehensive commercial fit-out project for a renowned veterinary practice located in Essex. The wellness center was thoughtfully designed to provide comprehensive pet care within a serene and welcoming environment, successfully realising the client&apos;s holistic approach.'.replace("&apos;", "'"),
    },
    process: {
      image: PROJECT_10_PROCESS_IMG, // To be added
      description:
        'Arch4D was involved with interior and joinery design, as well as the production of construction information. We also contributed to the design of the bespoke consultation pods, enhancing the identity of the space while maintaining functionality. The store is divided into sales, another for pet grooming, and consultation zones, all seamlessly integrated into a friendly and cohesive environment.'.replace("&apos;", "'"),
    },
    images: [
      PROJECT_10_IMAGE_1,
      PROJECT_10_IMAGE_2,
      PROJECT_10_IMAGE_3,
      PROJECT_10_IMAGE_4,
      PROJECT_10_IMAGE_5,
      PROJECT_10_IMAGE_6,
    ],
  },
];
