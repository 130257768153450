import { extendTheme } from '@chakra-ui/react';

// Supports weights 100-900
import '@fontsource-variable/inter';

const breakpoints = {
  sm: '0', // 480px
  md: '768px', // 768px
  lg: '1024px', // 992px
  xl: '1200px', // 1280px
  '2xl': '1440px', // 1536px
};

const theme = extendTheme((chakraTheme) => ({
  ...chakraTheme,
  breakpoints,
  styles: {
    global: {
      body: {
        lineHeight: 'normal',
      },
    },
  },
  colors: {
    ...chakraTheme.colors,
    transparent: 'transparent',
    white: '#ffffff',
    black: '#000000',
    gray: {
      100: '#D9D9D9',
      200: '#E2E8F0',
      500: '#656565',
      600: '#353535',
      700: '#2F2F2F',
      900: '#252525',
    },
    primary: '#F89634',
    secondary: '#00B5D8',
    button: {
      500: '#F89634',
    },
  },
  fonts: {
    heading: `'Inter Variable', sans-serif`,
    body: `'Inter Variable', sans-serif`,
  },
  textStyles: {
    inter8Light: {
      fontSize: ['8px'],
      fontWeight: '300',
      letterSpacing: '2px',
    },
    inter8Regular: {
      fontSize: ['8px'],
      fontWeight: '400',
      letterSpacing: '2px',
    },
    inter8Bold: {
      fontSize: ['8px'],
      fontWeight: '700',
      letterSpacing: '2px',
    },
    inter9Light: {
      fontSize: ['9px'],
      fontWeight: '300',
      letterSpacing: '2.25px',
    },
    inter9Regular: {
      fontSize: ['9px'],
      fontWeight: '400',
      letterSpacing: '2.25px',
    },
    inter9Bold: {
      fontSize: ['9px'],
      fontWeight: '700',
      letterSpacing: '2.25px',
    },
    inter10Light: {
      fontSize: ['10px'],
      fontWeight: '300',
      letterSpacing: '2.5px',
    },
    inter10Regular: {
      fontSize: ['10px'],
      fontWeight: '400',
      letterSpacing: '2.5px',
    },
    inter10Bold: {
      fontSize: ['10px'],
      fontWeight: '700',
      letterSpacing: '2.5px',
    },
    inter12LightNav: {
      fontSize: ['14px'],
      fontWeight: '300',
      letterSpacing: '2.5px',
    },
    inter12BoldNav: {
      fontSize: ['14px'],
      fontWeight: '700',
      letterSpacing: '2.5px',
    },
    inter12Light: {
      fontSize: ['12px'],
      fontWeight: '300',
      letterSpacing: '1.5px',
    },
    inter12Regular: {
      fontSize: ['12px'],
      fontWeight: '400',
      letterSpacing: '1.5px',
    },
    inter12Bold: {
      fontSize: ['12px'],
      fontWeight: '700',
      letterSpacing: '1.5px',
    },
    inter14Light: {
      fontSize: ['14px'],
      fontWeight: '300',
      letterSpacing: '1.5px',
    },
    inter14Regular: {
      fontSize: ['14px'],
      fontWeight: '400',
      letterSpacing: '1.5px',
    },
    inter14Bold: {
      fontSize: ['14px'],
      fontWeight: '700',
      letterSpacing: '1.5px',
    },
    inter16Light: {
      fontSize: ['16px'],
      fontWeight: '300',
      letterSpacing: '2.5px',
    },
    inter16Regular: {
      fontSize: ['16px'],
      fontWeight: '400',
      letterSpacing: '2.5px',
    },
    inter16Bold: {
      fontSize: ['16px'],
      fontWeight: '700',
      letterSpacing: '2.5px',
    },
    inter18Light: {
      fontSize: ['18px'],
      fontWeight: '300',
      letterSpacing: '4.5px',
    },
    inter18Regular: {
      fontSize: ['18px'],
      fontWeight: '400',
      letterSpacing: '4.5px',
    },
    inter18Bold: {
      fontSize: ['18px'],
      fontWeight: '700',
      letterSpacing: '4.5px',
    },
    inter20Light: {
      fontSize: ['20px'],
      fontWeight: '300',
      letterSpacing: '5px',
    },
    inter20Regular: {
      fontSize: ['20px'],
      fontWeight: '400',
      letterSpacing: '5px',
    },
    inter20Bold: {
      fontSize: ['20px'],
      fontWeight: '700',
      letterSpacing: '5px',
    },
    inter32Light: {
      fontSize: ['32px'],
      fontWeight: '300',
      letterSpacing: '5px',
    },
    inter32Regular: {
      fontSize: ['32px'],
      fontWeight: '400',
      letterSpacing: '5px',
    },
    inter32Bold: {
      fontSize: ['32px'],
      fontWeight: '700',
      letterSpacing: '5px',
    },
    inter60Bold: {
      fontSize: ['60px'],
      fontWeight: '700',
      letterSpacing: '15px',
    },
    inter96Bold: {
      fontSize: ['96px'],
      fontWeight: '700',
      letterSpacing: '24px',
    },
  },
}));

export default theme;
