import React, { useMemo } from 'react';

import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  PageWrapper,
  SectionTitle,
  ProjectsGallery,
} from '../../../components';
import { projects } from '../../../data/mock.data.projects';
import { Project } from '../../../models/Project';

interface ProjectsSectionProps {
  marginTop?: number;
}

const ProjectsSection = ({ marginTop = 0 }: ProjectsSectionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const orderedProjects = useMemo<Project[]>(
    () => projects.sort((a, b) => a.order - b.order),
    [projects]
  );

  const handleSeeAllProject = () => {
    navigate(`/projects`);
  };

  if (orderedProjects.length === 0) {
    return null;
  }

  return (
    <PageWrapper id="sections-3">
      <Box paddingTop={`${marginTop}px`} />
      <SectionTitle
        title={t('homepage.projects.title')}
        description={t('homepage.projects.description')}
      />
      {orderedProjects.length > 0 && (
        <ProjectsGallery
          items={orderedProjects}
          buttonLabel={t('homepage.projects.buttonLabel')}
          onClick={handleSeeAllProject}
        />
      )}
    </PageWrapper>
  );
};

export default ProjectsSection;
