import React from 'react';

import { Image, ImageProps } from '@chakra-ui/react';

interface ImageComponentProps {
  altMessage: string;
  src?: string;
  others?: ImageProps;
}

const ImageComponent = ({
  src,
  altMessage,
  others = {},
}: ImageComponentProps) => {
  return (
    <Image
      src={src}
      alt={altMessage}
      fallbackSrc={require('../assets/images/fallback-image.png')}
      {...others}
    />
  );
};

export default ImageComponent;
