import React, { ReactNode, useMemo } from 'react';

import { Container } from '@chakra-ui/react';

export enum PageAlignment {
  CENTER,
  START,
  END,
}

interface PageProps {
  id?: string;
  pageAlignment?: PageAlignment;
  bgUri?: string;
  bgColor?: string;
  children: ReactNode;
}

const PageWrapper = ({
  id,
  pageAlignment = PageAlignment.CENTER,
  bgUri,
  bgColor = 'white',
  children,
}: PageProps) => {
  const height = useMemo<number>(() => window.innerHeight, []);

  return (
    <Container
      id={id}
      centerContent
      justifyContent={
        pageAlignment === PageAlignment.CENTER
          ? 'center'
          : pageAlignment === PageAlignment.END
          ? 'flex-end'
          : 'flex-start'
      }
      padding={0}
      minHeight={height}
      minWidth={'100%'}
      maxWidth={'100%'}
      backgroundImage={bgUri}
      backgroundColor={bgColor}
      backgroundSize={'cover'}
      backgroundPosition={'center'}
    >
      {children}
    </Container>
  );
};

export default PageWrapper;
