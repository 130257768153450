import React, { useMemo } from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Center, IconButton, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { PageWrapper, Text } from '../../../components';
import { INTRO_VIDEO, INTRO_VIDEO_MOBILE } from '../../../constants/ImagePaths';
import { TextType } from '../../../models/Text';

const Intro = () => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 767px)');

  const height = useMemo<number>(() => window.innerHeight, []);

  const handleScrollDown = () => {
    const element = document.getElementById('expertise');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <PageWrapper>
      <Box position={'relative'} width={'100%'} height={height}>
        <Box
          position={'absolute'}
          top={0}
          left={0}
          bottom={0}
          right={0}
          bgColor={'rgba(0,0,0,0.4)'}
        />
        <video
          src={isMobile ? INTRO_VIDEO_MOBILE : INTRO_VIDEO}
          autoPlay
          loop
          muted
        />
        <Box
          position={'absolute'}
          bottom={0}
          left="10%"
          right={0}
          top={0}
          w="80%"
        >
          <Center
            flexDirection={'column'}
            alignItems={'center'}
            height={'100%'}
            w="75%"
          >
            <Text type={TextType.Inter20Regular} color="white">
              {t('homepage.welcome')}
            </Text>
          </Center>
        </Box>
        <Center position={'absolute'} bottom={0} width={'100%'}>
          <IconButton
            variant={'link'}
            aria-label="Scroll down to see the content"
            icon={<ChevronDownIcon boxSize={50} />}
            color={'white'}
            size={'md'}
            marginBottom={'40px'}
            onClick={handleScrollDown}
          />
        </Center>
      </Box>
    </PageWrapper>
  );
};

export default Intro;
