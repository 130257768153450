import React, { useCallback, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Textarea,
  Input,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SectionTitle, Text } from '../../../components';
import { GenericErrorMessage } from '../../../constants/Values';
import useEmailService from '../../../hooks/useEmailService';
import { InputProps } from '../../../models/Form';
import { TextType } from '../../../models/Text';

type Inputs = {
  firstName: string;
  lastName: string;
  businessName: string;
  businessEmail: string;
  message: string;
};

// TODO: Add reCAPTCHA
// https://blog.logrocket.com/implement-recaptcha-react-application/#implementing-recaptcha-in-react
const EmailMeForm = () => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const toast = useToast();
  const { sendEmail } = useEmailService();

  const formData = useMemo<InputProps[]>(
    () => [
      {
        order: 1,
        mobileOrder: 1,
        gridArea: 'firstName',
        label: 'homepage.contactUs.form.labels.firstName',
        formKey: 'firstName',
        requiredLabel: true,
      },
      {
        order: 2,
        mobileOrder: 3,
        gridArea: 'businessName',
        label: 'homepage.contactUs.form.labels.businessName',
        formKey: 'businessName',
      },
      {
        order: 3,
        mobileOrder: 2,
        gridArea: 'lastName',
        label: 'homepage.contactUs.form.labels.lastName',
        formKey: 'lastName',
        requiredLabel: true,
      },
      {
        order: 4,
        mobileOrder: 4,
        gridArea: 'businessEmail',
        label: 'homepage.contactUs.form.labels.businessEmail',
        formKey: 'businessEmail',
        requiredLabel: true,
        inputType: 'email',
      },
      {
        order: 5,
        mobileOrder: 5,
        gridArea: 'message',
        label: 'homepage.contactUs.form.labels.message',
        formKey: 'message',
        requiredLabel: true,
        multiline: true,
      },
    ],
    []
  );

  const formInputs = useMemo<InputProps[]>(
    () =>
      isMobile
        ? formData.sort((a, b) => a.mobileOrder - b.mobileOrder)
        : formData.sort((a, b) => a.order - b.order),
    [formData, isMobile]
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    clearErrors,
    reset,
  } = useForm<Inputs>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkbox, setCheckbox] = useState<boolean>(false);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [formInput, setFormInput] = useState<Inputs | null>();

  const validForm = useMemo<boolean>(
    () => checkbox && isValid,
    [checkbox, errors, isValid]
  );

  const handleSuccessEmail = () => {
    toast({
      title: 'Email sent!',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    reset();
  };

  const handleErrorEmail = () => {
    toast({
      title: GenericErrorMessage,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    clearErrors();
    setIsLoading(true);
    setFormInput(data);
    sendEmail(data)
      .then(() => handleSuccessEmail())
      .catch(() => handleErrorEmail())
      .finally(() => setIsLoading(false));
  };

  const renderFormInput = useCallback(
    ({
      order,
      label,
      formKey,
      inputType,
      requiredLabel = false,
      multiline = false,
    }: InputProps) => (
      <FormControl
        key={label}
        isInvalid={!!errors[formKey]}
        width={{ base: '100%', md: multiline ? '100%' : '50%' }}
      >
        <Flex
          flex={1}
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'flex-start' : 'center'}
          justifyContent={isMobile ? 'center' : 'space-between'}
          marginBottom={{ base: '10px', md: '40px' }}
          marginLeft={{
            base: 0,
            md: order % 2 === 0 ? '20px' : 0,
            lg: order % 2 === 0 ? '40px' : 0,
          }}
        >
          <Box
            marginBottom={{ base: '10px', md: 0 }}
            width={{ base: '100%', md: multiline ? '12.5%' : '25%' }}
          >
            <Text
              type={{
                base: TextType.Inter12Light,
                md: TextType.Inter14Light,
                lg: TextType.Inter14Light,
              }}
              color={'gray.500'}
            >{`${t(label)}${requiredLabel ? '*' : ''}`}</Text>
          </Box>
          {multiline ? (
            <Textarea
              borderWidth={'1px'}
              borderRadius={0}
              focusBorderColor={errors[formKey] ? 'red' : 'gray.100'}
              errorBorderColor="red"
              _invalid={{
                borderColor: 'red',
              }}
              variant="filled"
              flex={1}
              marginLeft={{ base: 0, md: 0 }}
              resize={'vertical'}
              noOfLines={3}
              {...register(formKey, { required: requiredLabel })}
            />
          ) : (
            <Input
              borderWidth={'1px'}
              borderRadius={0}
              focusBorderColor={errors[formKey] ? 'red' : 'gray.100'}
              errorBorderColor="red"
              _invalid={{
                borderColor: 'red',
              }}
              variant="filled"
              type={inputType}
              width={{ base: '100%', md: '75%' }}
              {...register(formKey, { required: requiredLabel })}
            />
          )}
        </Flex>
      </FormControl>
    ),
    [errors, isMobile]
  );

  return (
    <Box
      marginY={{ base: '20px', md: '40px' }}
      padding={{ base: 0, md: '12px' }}
      width={{ base: '100%', md: '80%' }}
      paddingX={{ base: '20px', md: 0 }}
    >
      <SectionTitle noPadding title={t('homepage.contactUs.form.title')} />
      {formInputs.length > 0 && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            width={'100%'}
            flexDirection={{ base: 'column', md: 'row' }}
            flexWrap={{ base: 'nowrap', md: 'wrap' }}
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            {formInputs.map((item) => renderFormInput(item))}
            <Box width={{ base: '100%', md: `${700 / 8}%` }}>
              <Button
                isDisabled={!validForm}
                isLoading={isLoading}
                loadingText={'Submitting'}
                bgColor={'primary'}
                color={'white'}
                type="submit"
                width={{ base: '50%', md: '33.3333%' }}
                borderRadius={0}
              >
                <Text
                  type={{
                    base: TextType.Inter14Bold,
                    md: TextType.Inter18Bold,
                  }}
                  color="white"
                >
                  {t('homepage.contactUs.form.button').toUpperCase()}
                </Text>
              </Button>
              <Box marginY={'15px'}>
                <Checkbox
                  size="lg"
                  isRequired
                  borderColor={'gray.200'}
                  isChecked={checkbox}
                  onChange={(e) => setCheckbox(e.target.checked)}
                  colorScheme={'button'}
                >
                  <Text
                    type={{
                      base: TextType.Inter10Light,
                      md: TextType.Inter12Light,
                    }}
                    color="gray.500"
                  >
                    {t('homepage.contactUs.form.checkbox').replace(
                      '{{privacyPolicy}}',
                      t('homepage.contactUs.form.privacyPolicy')
                    )}
                    <Link
                      to={'/privacyPolicy'}
                      style={{ textDecoration: 'underline', marginLeft: 4 }}
                    >
                      {t('homepage.contactUs.form.privacyPolicy')}
                    </Link>
                  </Text>
                </Checkbox>
              </Box>
            </Box>
          </Flex>
        </form>
      )}
    </Box>
  );
};

export default EmailMeForm;
