import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

interface NavigationData {
  scrollToAnchor: string | null;
  setScrollToAnchor: Dispatch<SetStateAction<string | null>>;
}

export const NavigationContext = createContext<NavigationData>(
  {} as NavigationData
);

const NavigationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [scrollToAnchor, setScrollToAnchor] = useState<string | null>(null);

  return (
    <NavigationContext.Provider
      value={{
        scrollToAnchor,
        setScrollToAnchor,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);

export default NavigationProvider;
