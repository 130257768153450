import * as React from 'react';

import { Container } from '@react-email/container';
import { Head } from '@react-email/head';
import { Heading } from '@react-email/heading';
import { Html } from '@react-email/html';
import { Text } from '@react-email/text';

import { EmailData } from '../models/Form';

interface EmailProps extends EmailData {}

const EmailTemplate = ({
  firstName,
  lastName,
  businessName,
  message,
}: EmailProps) => {
  return (
    <Html lang="en" dir="ltr">
      <Head>
        <title>{`${firstName} ${lastName}${
          businessName ? ` from ${businessName} ` : ''
        } wants to get in touch with you!`}</title>
      </Head>
      <Heading as="h2">Hi, arch4D!</Heading>
      <Text>
        {`You have the following message from ${firstName} ${lastName}:`}
      </Text>
      <Container border={1}>
        <Text>{message}</Text>
      </Container>
    </Html>
  );
};

export default EmailTemplate;
