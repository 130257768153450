import React from 'react';

import { Button, Flex, Grid, GridItem, Spinner } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Footer,
  Header,
  MemberCard,
  PageWrapper,
  SectionTitle,
  Spacer,
  Text,
} from '../../components';
import { useHeader } from '../../contexts/useHeader';
import { useNavigation } from '../../contexts/useNavigation';
import { aboutLinks, teamData } from '../../data/mock.data.about';
import { TextType } from '../../models/Text';

import CollabGrid from './components/CollabGrid';

const AboutUsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setScrollToAnchor } = useNavigation();
  const { headerDimensions } = useHeader();

  const handleContactUs = () => {
    navigate('/');
    setScrollToAnchor('contact');
  };

  return (
    <>
      <Header links={aboutLinks} justLightMode />
      <PageWrapper>
        <Spacer size={headerDimensions?.height || 0} />
        <SectionTitle
          title={t('aboutUs.title')}
          description={t('aboutUs.description1')}
          description2={t('aboutUs.description2')}
        /> 
        {teamData.length > 0 && (
          <Grid
            flex={1}
            width={'100%'}
            templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
            gap={{ base: '8px', md: '25px' }}
          >
            {teamData.map((member, index) => (
              <GridItem
                key={`item-${member.name}-${index}`}
                w="100%"
                aspectRatio={1}
              >
                <MemberCard heroImage={member.image}>
                  <Flex
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                  >
                    <Text
                      color="gray.500"
                      type={{
                        base: TextType.Inter12Bold,
                        md: TextType.Inter18Bold,
                      }}
                    >
                      {member.name.toUpperCase()}
                    </Text>
                    <Spacer size={12} />
                    {member.role && (
                      <>
                        <Text type={{ base: TextType.Inter12Light, md: TextType.Inter14Light }}
                        color="gray.500"
                        others={{ textAlign: 'center' }}>{member.role}</Text>
                        <Spacer size={6} />
                      </>
                    )}
                    {member.about && (
                      <>
                        <Text type={{ base: TextType.Inter12Light, md: TextType.Inter14Light }}
                        color="gray.500"
                        others={{ textAlign: 'center' }}>{member.about}</Text>
                        <Spacer size={6} />
                      </>
                    )}
                  </Flex>
                </MemberCard>
              </GridItem>
            ))}
          </Grid>
        )}
        <Spacer size={40} />
        <SectionTitle
          title={t('aboutUs.collabTitle')}
          description={t('aboutUs.collabDescription')}
        />
        <Spacer size={20} />
        <CollabGrid />
        <Spacer size={40} />
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          width={'100%'}
        >
          <Text
            type={{ base: TextType.Inter12Light, md: TextType.Inter14Light }}
          >
            {t('aboutUs.buttonMessage')}
          </Text>
          <Button
            variant="solid"
            bgColor={'primary'}
            marginTop={'15px'}
            width={{ base: '50%', md: '33.3333%' }}
            borderRadius={0}
            spinner={
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="secondary"
              />
            }
            onClick={handleContactUs}
          >
            <Text
              type={{ base: TextType.Inter12Bold, md: TextType.Inter14Bold }}
              color="white"
            >
              {t('aboutUs.buttonTitle').toUpperCase()}
            </Text>
          </Button>
        </Flex>
        <Spacer size={20} />
        <Footer />
      </PageWrapper>
    </>
  );
};

export default AboutUsPage;
