import React from 'react';

import { Flex, Container } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { TextType } from '../models/Text';

import { Text } from '.';

interface LogoProps {
  logoPath: string;
  size?: number;
  color?: string;
  withText?: boolean;
}

const Logo = ({
  logoPath,
  color = 'white',
  size = 100,
  withText = true,
}: LogoProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickLogo = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      navigate('/');
    }
  };

  return (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
      paddingY={'10px'}
      onClick={handleClickLogo}
      cursor={'pointer'}
    >
      <Container
        padding={0}
        aspectRatio={1}
        width={`${size}px`}
        backgroundImage={`url(${logoPath})`}
        backgroundSize={'contain'}
        backgroundPosition={'center'}
        borderRightColor={color}
        borderRightWidth={withText ? 1 : 0}
      />
      {withText && (
        <Flex flexDirection={'column'} marginLeft={'10px'}>
          {t('header.logoTitle')
            .split(' ')
            .map((item, index) => (
              <Text
                key={`item-${index}-${item}`}
                type={{
                  base: TextType.Inter14Regular,
                  md: TextType.Inter14Regular,
                }}
                others={{ textTransform: 'uppercase' }}
                color={color}
              >
                {item}
              </Text>
            ))}
        </Flex>
      )}
    </Flex>
  );
};

export default Logo;
