import React, { useCallback, useState } from 'react';

import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, ButtonGroup, Flex, IconButton } from '@chakra-ui/react';

import { Text } from '../components';
import { TextType } from '../models/Text';

export interface Filter {
  label: string;
  value: string;
  selected?: boolean;
}

interface FilterChipsProps {
  filters: Filter[];
  onSelect?: (value: Filter) => void;
  onRemove?: (value: Filter) => void;
  multi?: boolean;
}

const FilterChips = ({
  filters,
  multi = false,
  onSelect = () => null,
  onRemove = () => null,
}: FilterChipsProps) => {
  const [values, setValues] = useState<Filter[]>(filters || []);

  const handleSelectFilter = useCallback(
    (selected: Filter) => {
      if (selected.selected) {
        return;
      }

      const newValues = values.map((filter) => {
        if (filter.value === selected.value) {
          filter.selected = true;
        }

        return filter;
      });

      setValues(newValues);
      onSelect(selected);
    },
    [values, onSelect]
  );

  const handleRemoveFilter = useCallback(
    (selected: Filter) => {
      if (!selected.selected) {
        return;
      }

      const newValues = values.map((filter) => {
        if (filter.value === selected.value) {
          filter.selected = false;
        }

        return filter;
      });

      setValues(newValues);
      onRemove(selected);
    },
    [values, onRemove]
  );

  const renderFilter = ({ item, index }: { item: Filter; index: number }) => {
    return (
      <ButtonGroup
        key={`${item.label}-${index}`}
        isAttached
        variant="outline"
        width={{ base: '40%', md: 'auto' }}
        borderBottom={`${item.selected ? 2 : 1}px solid black`}
        marginRight={{ base: '20px', md: '25px' }}
      >
        <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          width={'100%'}
        >
          <Button variant={'unstyled'} onClick={() => handleSelectFilter(item)}>
            <Text
              type={
                !item.selected
                  ? { base: TextType.Inter12Light, md: TextType.Inter14Light }
                  : { base: TextType.Inter12Bold, md: TextType.Inter14Bold }
              }
            >
              {item.label.toUpperCase()}
            </Text>
          </Button>
          {multi && (
            <IconButton
              variant={'unstyled'}
              aria-label="Remove filter"
              icon={<CloseIcon boxSize={2} />}
              onClick={() => handleRemoveFilter(item)}
              visibility={item.selected ? 'visible' : 'hidden'}
            />
          )}
        </Flex>
      </ButtonGroup>
    );
  };

  if (!filters || filters.length < 1) {
    return null;
  }

  return (
    <Box width={{ base: '100%', md: '80%' }} paddingX={{ base: '20px', md: 0 }}>
      <Flex
        justifyContent={'flex-start'}
        flexDirection={'row'}
        alignItems={'center'}
        flexWrap={'wrap'}
      >
        {filters.map((item, index) => renderFilter({ item, index }))}
      </Flex>
    </Box>
  );
};

export default FilterChips;
