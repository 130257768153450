import React from 'react';

import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  Text,
  ImageComponent,
  PageWrapper,
  SectionTitle,
} from '../../../components';
import { WORLD_MAP_IMG } from '../../../constants/ImagePaths';
import { locations } from '../../../data/mock.data.contact';
import { TextType } from '../../../models/Text';

import { EmailMeForm } from '.';

interface ContactSectionProps {
  marginTop?: number;
}

const ContactUsSection = ({ marginTop = 0 }: ContactSectionProps) => {
  const { t } = useTranslation();

  return (
    <PageWrapper id="contact">
      <Box paddingTop={`${marginTop}px`} />
      <SectionTitle
        title={t('homepage.contactUs.title')}
        description={t('homepage.contactUs.description')}
      />
      <ImageComponent
        altMessage="arch-4D locations"
        src={WORLD_MAP_IMG}
        others={{
          width: { base: '100%', md: '50%' },
          objectFit: 'cover',
          marginY: { base: '60px', md: '40px' },
          paddingX: { base: '20px', md: 0 },
        }}
      />
      {locations.length > 0 && (
        <Grid
          width={{ base: '100%', md: '80%' }}
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          gap={{ base: '40px', md: '25px' }}
        >
          {locations.map((loc, index) => (
            <GridItem key={`item-${index}-${loc.name}`} width={'100%'}>
              <Flex
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Text
                  type={{
                    base: TextType.Inter14Bold,
                    md: TextType.Inter18Bold,
                  }}
                  color={'gray.500'}
                  others={{
                    marginBottom: '20px',
                  }}
                >
                  {loc.name.toUpperCase()}
                </Text>
                {loc.contact && (
                  <>
                    {loc.contact.phoneNumber && (
                      <Text
                        type={{
                          base: TextType.Inter12Light,
                          md: TextType.Inter14Light,
                        }}
                        color={'gray.500'}
                      >
                        {loc.contact.phoneNumber}
                      </Text>
                    )}
                    <Text
                      type={{
                        base: TextType.Inter12Light,
                        md: TextType.Inter14Light,
                      }}
                      color={'gray.500'}
                    >
                      {loc.contact.email}
                    </Text>
                  </>
                )}
              </Flex>
            </GridItem>
          ))}
        </Grid>
      )}
      <EmailMeForm />
    </PageWrapper>
  );
};

export default ContactUsSection;
