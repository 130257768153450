import React, { useMemo } from 'react';

import {
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  LinkBox,
  LinkOverlay,
  ResponsiveObject,
  Spinner,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Project } from '../models/Project';
import { TextType } from '../models/Text';

import Spacer from './Spacer';
import SquareCard from './SquareCard';
import Text from './Text';

interface ProjectsGalleryProps {
  items: Project[];
  gap?: ResponsiveObject<string>;
  buttonLabel?: string;
  onClick?: () => void;
}

const ProjectsGallery = ({
  items,
  gap = { base: '8px', lg: '25px' },
  buttonLabel,
  onClick = () => null,
}: ProjectsGalleryProps) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 767px)');

  const itemsToDisplay = useMemo<Project[]>(
    () => items.slice(0, isMobile ? 3 : 6),
    [isMobile, items]
  );

  return (
    <>
      <Grid
        flex={1}
        width={'100%'}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        gap={gap}
      >
        {itemsToDisplay.map((project, index) => (
          <GridItem
            key={`item-${project.title}-${index}`}
            w="100%"
            aspectRatio={1}
          >
            <SquareCard
              heroImage={project.heroImage}
              withShadow
              centered
              visibleNode={
                <Center
                  height={'100%'}
                  alignItems={'flex-end'}
                  paddingBottom={'30px'}
                >
                  <Text
                    type={{
                      base: TextType.Inter14Regular,
                      md: TextType.Inter18Regular,
                      lg: TextType.Inter20Regular,
                    }}
                    color="white"
                  >
                    {project.title.toUpperCase()}
                  </Text>
                  <Spacer mobileSize={15} size={30} />
                </Center>
              }
            >
              <Center>
                <Flex
                  height={'100%'}
                  width={'85%'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  marginY={'12px'}
                >
                  {!isMobile ? (
                    <LinkBox>
                      <LinkOverlay href={`/projects/${project.id}`}>
                        <Text
                          type={{
                            base: TextType.Inter14Regular,
                            md: TextType.Inter18Regular,
                            lg: TextType.Inter20Regular,
                          }}
                          color="white"
                        >
                          {project.title.toUpperCase()}
                        </Text>
                      </LinkOverlay>
                    </LinkBox>
                  ) : (
                    <Text
                      type={{
                        base: TextType.Inter14Regular,
                        md: TextType.Inter18Regular,
                        lg: TextType.Inter20Regular,
                      }}
                      color="white"
                    >
                      {project.title.toUpperCase()}
                    </Text>
                  )}
                  <Spacer size={12} />
                  <Text
                    type={{
                      base: TextType.Inter12Light,
                      md: TextType.Inter14Light,
                    }}
                    color="white"
                  >
                    {project.shortDescription}
                  </Text>

                  {isMobile && (
                    <>
                      <Spacer size={12} />
                      <LinkBox>
                        <LinkOverlay href={`/projects/${project.id}`}>
                          <Text
                            type={{
                              base: TextType.Inter12Light,
                              md: TextType.Inter14Light,
                            }}
                            color="white"
                            others={{
                              textDecoration: 'underline',
                              textDecorationColor: 'white',
                              textUnderlineOffset: 8,
                            }}
                          >
                            {t(
                              'homepage.projects.findOutMoreBtn'
                            ).toUpperCase()}
                          </Text>
                        </LinkOverlay>
                      </LinkBox>
                    </>
                  )}
                  <Spacer size={30} />
                </Flex>
              </Center>
            </SquareCard>
          </GridItem>
        ))}
      </Grid>
      <Button
        variant="solid"
        bgColor={'primary'}
        marginTop={'30px'}
        width={{ base: '50%', md: '33.3333%' }}
        borderRadius={0}
        onClick={onClick}
        spinner={
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="secondary"
          />
        }
      >
        <Text
          type={{ base: TextType.Inter12Bold, md: TextType.Inter14Bold }}
          color="white"
        >
          {(buttonLabel || t('homepage.projects.buttonLabel')).toUpperCase()}
        </Text>
      </Button>
    </>
  );
};

export default ProjectsGallery;
